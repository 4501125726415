export default {
  EVENTS: {
    CATEGORIES: {
      CURRENT: 'current',
      PAST: 'past',
      TEMPLATES: 'templates',
    },
  },
};

export const INPUT_TYPES = {
  TEXT_FIELD: 1,
  TEXT_AREA: 2,
  CHECKBOX: 3,
  SELECT: 4,
  RADIO: 5,
  IMAGE: 6,
  LABEL: 7,
  CITY: 8,
  FILE: 9,
  DATE_TIME: 10,
  TERMS_CONDITIONS: 20,
};
