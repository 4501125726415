export default {
  en: {
    activityStream: 'Activity stream',
    activitiesLimit: 'You can create up to 5 activities!',
    maxActivity: 'Max amount of activities created',
    maxActivityDescription: 'You\'ve reached the maximum amount of activities that you can create',
    addActivity: 'Add activity',
    activityCreated: 'Activity created!',
    streamNickname: 'This name will be viewed in the stream',
    createdActivityDescription: 'The activity has been succesfully created.',
    errorCreating: 'Error creating activity',
    errorCreatingDescription: 'There was an issue creating the activity.',
    errorEditing: 'Error editing activity',
    errorEditingDescription: 'There was an issue editing the activity.',
    errorDeleting: 'Error deleting activity',
    errorDeletingDescription: 'There was an issue deleting the activity.',
    activityDeleted: 'Activity deleted!',
    deletedActivityDescription: 'The activity has been succesfully deleted.',
    activityEdited: 'The activity has been succesfully edited',
    editedActivityDescription: 'Activity edited',
    editActivity: 'Edit activity',
    nameOfActivity: 'Name of activity',
    activityImage: 'Activity image',
    suggestedText: 'The suggested image size is 400 x 262px',
    namePlaceholder: 'Ex: Activity name',
    linkToStream: 'Streaming link',
    descriptionPlaceholder: 'Describe the activity that will take place. (Maximum 450 characters)',
    saveChanges: 'Save changes',
    deleteActivity: 'Delete activity',
    deleteActivityDescription: 'Are you sure you want to delete this activity?',
    registerAttendeesList: 'Registered Attendees',
    registeredListDesciption: 'View all of the attendees who registered to your activity',
    menuRegisterAttendes: 'Register attendees menu',
    download: 'Download',
    tableAttendeeName: 'Name',
    tableAttendeeLastName: 'Last Name',
    registerAttendees: 'Requiere all attendees to register before joining this activity.',
    limitAttendees: 'Limit the amount of attendees that can register for this activity.',
    searchTableAttendee: 'Min 4 letters.',
  },
  es: {
    activityStream: 'Streaming de la actividad',
    activitiesLimit: 'Puedes crear hasta 5 actividades!',
    maxActivity: 'Máxima cantidad de actividades creada.',
    maxActivityDescription: 'Has llegado al límite de actividades que pueden ser creadas',
    addActivity: 'Agregar actividad',
    activityCreated: 'Actividad Creada!',
    streamNickname: 'Este nombre será visto en el stream',
    createdActivityDescription: 'La actividad ha sido creada exitosamente.',
    errorCreating: 'Error creando actividad',
    errorCreatingDescription: 'Ocurrió un problema creando la actividad.',
    errorDeleting: 'Error eliminando actividad',
    errorDeletingDescription: 'Hubo un error al eliminar esta actividad.',
    errorEditing: 'Error editando la actividad',
    errorEditingDescription: 'Hubo un error al editar esta actividad.',
    activityDeleted: 'Actividad eliminada!',
    deletedActivityDescription: 'Actividad eliminada exitosamente.',
    activityEdited: 'Actividad editada',
    editedActivityDescription: 'La actividad ha sido editada exitosamete',
    editActivity: 'Editar actividad',
    nameOfActivity: 'Nombre de actividad',
    activityImage: 'Imagen de actividad',
    suggestedText: 'Las dimensiones sugeridas son de 400 x 262px',
    namePlaceholder: 'Ej: Nombre de actividad',
    linkToStream: 'Link a stream',
    descriptionPlaceholder: 'Describe información sobre la actividad. (Máximo 450 caracteres)',
    saveChanges: 'Guardar cambios',
    deleteActivity: 'Eliminar Actividad',
    deleteActivityDescription: '¿Estás seguro de eliminar esta actividad?',
    registerAttendeesList: 'Participantes registrados',
    registeredListDesciption: 'Ver todos los participantes que se han inscrito a su actividad',
    menuRegisterAttendes: 'Menú de registro de asistentes',
    download: 'Descargar',
    tableAttendeeName: 'Nombre',
    tableAttendeeLastName: 'Apellido',
    registerAttendees: 'Requiere que todos los participantes se registren antes de unirse a esta actividad.',
    limitAttendees: 'Limitar la cantidad de participantes que se pueden registrar en esta actividad.',
    searchTableAttendee: 'Min 4 letras.',
  },
  fr: {
    activityStream: 'Activité du streaming',
    activitiesLimit: 'Vous pouvez créer jusqu\'à 5 activités !',
    maxActivity: 'Nombre maximal d\'activités créées',
    maxActivityDescription: 'Vous avez atteint le nombre maximum d\'activités que vous pouvez créer.',
    addActivity: 'Ajouter une activité',
    activityCreated: 'Activité créée !',
    streamNickname: 'Ce nom sera affiché dans le streaming',
    createdActivityDescription: 'L\'activité a été créée avec succès',
    errorCreating: 'Erreur lors de la création de l\'activité',
    errorCreatingDescription: 'Un problème est survenu lors de la création de l\'activité',
    errorEditing: 'Erreur lors de la édition de l\'activité',
    errorEditingDescription: 'Un problème est survenu lors de la édition de l\'activité',
    errorDeleting: ' Erreur lors de la suppression de l\'activité',
    errorDeletingDescription: 'Un problème s\'est produit lors de la suppression de l\'activité',
    activityDeleted: 'Activité supprimée !',
    deletedActivityDescription: 'L\'activité a été supprimée avec succès.',
    activityEdited: 'L\'activité a été modifiée avec succès',
    editedActivityDescription: 'Activité modifiée',
    editActivity: 'Modifier l\'activité',
    nameOfActivity: 'Nom de l\'activité',
    activityImage: 'Image de l\'activité',
    suggestedText: 'La taille d\'image suggérée est de 400 x 262px',
    namePlaceholder: 'Ex : Nom de l\'activité',
    linkToStream: 'Lien de diffusion en continu',
    descriptionPlaceholder: 'Décrivez l\'activité qui aura lieu. (Maximum 450 caractères)',
    saveChanges: 'Enregistrer les modifications',
    deleteActivity: 'Supprimer l\'activité',
    deleteActivityDescription: 'Voulez-vous vraiment supprimer cette activité?',
    registerAttendeesList: 'Participants inscrits',
    registeredListDesciption: 'Affichez tous les participants qui se sont inscrits à votre activité',
    menuRegisterAttendes: 'Enregistrer le menu des participants',
    download: 'Télécharger',
    tableAttendeeName: 'Nom',
    tableAttendeeLastName: 'Nom de famille',
    registerAttendees: 'Exige que tous les participants s\'inscrivent avant de rejoindre cette activité.',
    limitAttendees: 'Limitez le nombre de participants pouvant s\'inscrire à cette activité.',
    searchTableAttendee: 'Min 4 des lettres.',
  },
  de: {
    activityStream: 'Aktivitätenstream',
    activitiesLimit: 'Sie können bis zu 5 Aktivitäten erstellen!',
    maxActivity: 'Maximale Anzahl erstellter Aktivitäten',
    maxActivityDescription: 'Sie haben die maximale Anzahl an Aktivitäten erreicht, die Sie erstellen können',
    addActivity: 'Aktivität hinzufügen',
    activityCreated: 'Aktivität wurde erstellt!',
    streamNickname: 'Dieser Name wird im Stream angesehen',
    createdActivityDescription: 'Die Aktivität wurde erfolgreich erstellt.',
    errorCreating: 'Fehler beim Erstellen der Aktivität',
    errorCreatingDescription: 'Es gab ein Problem beim Erstellen der Aktivität.',
    errorEditing: 'Fehler beim Bearbeiten der Aktivität',
    errorEditingDescription: 'Es gab ein Problem beim Bearbeiten der Aktivität.',
    errorDeleting: 'Fehler beim Löschen der Aktivität',
    errorDeletingDescription: 'Es gab ein Problem beim Löschen der Aktivität.',
    activityDeleted: 'Aktivität gelöscht!',
    deletedActivityDescription: 'Die Aktivität wurde erfolgreich gelöscht.',
    activityEdited: 'Die Aktivität wurde erfolgreich bearbeitet',
    editedActivityDescription: 'Aktivität bearbeitet',
    editActivity: 'Aktivität bearbeiten',
    nameOfActivity: 'Name der Aktivität',
    activityImage: 'Bild der Aktivität',
    suggestedText: 'Die empfohlene Bildgröße ist 400 x 262 Pixel',
    namePlaceholder: 'Beispiel: Name der Aktivität',
    linkToStream: 'Streaming-Link',
    descriptionPlaceholder: 'Beschreiben Sie die Aktivität, die stattfinden wird. (Maximal 450 Zeichen)',
    saveChanges: 'Änderungen speichern',
    deleteActivity: 'Aktivität löschen',
    deleteActivityDescription: 'Sind Sie sicher, dass Sie diese Aktivität löschen möchten?',
    registerAttendeesList: 'Angemeldete Teilnehmer',
    registeredListDesciption: 'Alle Teilnehmer anzeigen, die sich für Ihre Aktivität angemeldet haben',
    menuRegisterAttendes: 'Teilnehmermenü registrieren',
    download: 'Herunterladen',
    tableAttendeeName: 'Name',
    tableAttendeeLastName: 'Nachname',
    registerAttendees: 'Alle Teilnehmer müssen sich registrieren, bevor sie an dieser Aktivität teilnehmen können.',
    limitAttendees: 'Begrenzen Sie die Anzahl der Teilnehmer, die sich für diese Aktivität registrieren können.',
    searchTableAttendee: 'Mindestens 4 Buchstaben.',
  },
};
