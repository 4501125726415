export default {
  en: {
    title: 'Events',
    templates: 'Templates',
    sidebar: {
      myEvents: 'My events',
      myEvent: 'My event',
      dashboard: 'Dashboard',
      modules: 'Modules',
    },
    actions: {
      createEvent: 'Create event',
      addLogo: 'Add logo to an event',
      editLogo: 'Edit logo',
    },
    messages: {
      info: {
        noAttendees: 'There are still no attendees',
        emptyState: 'You don\'t have any events yet. Do you want to create your first event?',
      },
    },
    modules: {
      title: 'Event modules',
      add: 'Add module',
      addPlural: 'Add modules',
      info: 'Add additional modules to your event and customize it',
      communications: 'Communications',
      communicationsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      attendees: 'Attendees',
      attendeesInfo: 'Manage all your event attendees',
      agenda: 'Agenda',
      agendaInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      businessConferences: 'Business conferences',
      businessConferencesInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      stands: 'Stands',
      standsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      surveys: 'Surveys',
      surveysInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      pois: 'Pois',
      poisInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      app: 'App',
      appInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      settings: 'Settings',
      settingsInfo: 'Administer and manage the general information of your event',
      ticketTypes: 'Ticket types',
      ticketTypesInfo: 'Manage the different types of registration for your assistants.',
      website: 'Website',
      websiteInfo: 'Customize your event\'s website for registration, ticketing, etc.',
    },
    statuses: {
      active: 'Active',
      inactive: 'Inactive',
      all: 'All',
      past: 'Past',
      current: 'Current',
      templates: 'Templates',
    },
    attributes: {
      eventLogo: 'Event logo',
    },
  },
  es: {
    title: 'Eventos',
    templates: 'Plantillas',
    sidebar: {
      myEvents: 'Mis eventos',
      myEvent: 'Mi evento',
      dashboard: 'Dashboard',
      modules: 'Módulos',
    },
    actions: {
      createEvent: 'Crear evento',
      addLogo: 'Agregar logo a un evento',
      editLogo: 'Modificar logo',
    },
    messages: {
      info: {
        noAttendees: 'Aún no hay asistentes',
        emptyState: 'Todavía no tienes eventos. ¿Quieres crear tu primer evento?',
      },
    },
    modules: {
      title: 'Módulos de mi evento',
      add: 'Agregar módulo',
      addPlural: 'Agregar módulos',
      info: 'Agrega módulos adicionales a tu evento y personalízalo a tu medida',
      communications: 'Comunicaciones',
      communicationsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      attendees: 'Asistentes',
      attendeesInfo: 'Administra a todos los asistentes de tu evento.',
      agenda: 'Agenda',
      agendaInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      businessConferences: 'Rueda de negocios',
      businessConferencesInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      stands: 'Stands',
      standsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      surveys: 'Encuestas',
      surveysInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      pois: 'Puntos de interés',
      poisInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      app: 'App',
      appInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      settings: 'Configuraciones',
      settingsInfo: 'Administra y gestiona la información general de tu evento',
      ticketTypes: 'Tipos de entrada',
      ticketTypesInfo: 'Administra los diferentes tipos de registro para tus asistentes.',
      website: 'Sitio web',
      websiteInfo: 'Personaliza el sitio web de tu evento para registros, venta de boletería, etc.',
    },
    statuses: {
      active: 'Activo',
      inactive: 'Inactivo',
      all: 'Todos',
      past: 'Pasados',
      current: 'Actuales',
      templates: 'Plantillas',
    },
    attributes: {
      eventLogo: 'Logo del evento',
    },
  },
  fr: {
    title: 'Événements',
    templates: 'Modèles',
    sidebar: {
      myEvents: 'Mes événements',
      myEvent: 'Mon événement',
      dashboard: 'Tableau de bord',
      modules: 'Modules',
    },
    actions: {
      createEvent: 'Créer un événement',
      addLogo: 'Ajouter un logo à un événement',
      editLogo: 'Modifier le logo',
    },
    messages: {
      info: {
        noAttendees: "Il n'y a toujours pas de participants",
        emptyState: "Vous n'avez encore aucun événement. Voulez-vous créer votre premier événement ?",
      },
    },
    modules: {
      title: 'Modules événementiels',
      add: 'Ajouter un module',
      addPlural: 'Ajouter des modules',
      info: 'Ajoutez des modules supplémentaires à votre événement et personnalisez-le',
      communications: 'Communication',
      communicationsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      attendees: 'Participants',
      attendeesInfo: 'Gérez tous les participants à vos événements',
      agenda: 'Agenda',
      agendaInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      businessConferences: "Conférences d'affaires",
      businessConferencesInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      stands: 'Stands',
      standsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      surveys: 'Sondages',
      surveysInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      pois: 'Pois',
      poisInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      app: 'App',
      appInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      settings: 'Réglages',
      settingsInfo: 'Administrer et gérer les informations générales de votre événement',
      ticketTypes: 'Types de billets',
      ticketTypesInfo: "Gérez les différents types d'inscription pour vos assistants.",
      website: 'Site Web',
      websiteInfo: "Personnalisez le site web de votre événement pour l'inscription, la billetterie, etc.",
    },
    statuses: {
      active: 'Active',
      inactive: 'Inactive',
      all: 'Toutes',
      past: 'Passé',
      current: 'Actuel',
      templates: 'Modèles',
    },
    attributes: {
      eventLogo: "Logo de l'événement",
    },
  },
  de: {
    title: 'Ereignisse',
    templates: 'Vorlagen',
    sidebar: {
      myEvents: 'Meine Veranstaltungen',
      myEvent: 'Meine Veranstaltung',
      dashboard: 'Armaturenbrett',
      modules: 'Module',
    },
    actions: {
      createEvent: 'Veranstaltung erstellen',
      addLogo: 'Logo zu einer Veranstaltung hinzufügen',
      editLogo: 'Logo bearbeiten',
    },
    messages: {
      info: {
        noAttendees: 'Es gibt immer noch keine Teilnehmer',
        emptyState: 'Sie haben noch keine Veranstaltungen. Möchten Sie Ihre erste Veranstaltung erstellen?',
      },
    },
    modules: {
      title: 'Event-Module',
      add: 'Modul hinzufügen',
      addPlural: 'Module hinzufügen',
      info: 'Fügen Sie Ihrer Veranstaltung zusätzliche Module hinzu und passen Sie sie an',
      communications: 'Kommunikation',
      communicationsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      attendees: 'Teilnehmer',
      attendeesInfo: 'Verwalten Sie alle Ihre Veranstaltungsteilnehmer',
      agenda: 'Tagesordnung',
      agendaInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      businessConferences: 'Geschäftskonferenzen',
      businessConferencesInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      stands: 'Ständer',
      standsInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      surveys: 'Umfragen',
      surveysInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      pois: 'Pois',
      poisInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      app: 'App',
      appInfo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      settings: 'Einstellungen',
      settingsInfo: 'Verwaltung und Verwaltung der allgemeinen Informationen Ihrer Veranstaltung',
      ticketTypes: 'Arten von Tickets',
      ticketTypesInfo: 'Verwalten Sie die verschiedenen Arten der Registrierung für Ihre Assistenten.',
      website: 'Webseite',
      websiteInfo: 'Passen Sie die Website Ihrer Veranstaltung für Registrierung, Ticketverkauf usw. an.',
    },
    statuses: {
      active: 'Aktiv',
      inactive: 'Inaktiv',
      all: 'Alles',
      past: 'Vergangenheit',
      current: 'Aktuell',
      templates: 'Vorlagen',
    },
    attributes: {
      eventLogo: 'Logo der Veranstaltung',
    },
  },
};
