import humps from 'humps';

const endpoints = {
  attendeeTypes: ({ eventUri }) => `events/${eventUri}/attendee_types?source=sponsors-editor`,
  countries: 'countries',
  regions: ({ countryId }) => `countries/${countryId}/regions`,
  cities: ({ countryId, regionId }) => `countries/${countryId}/regions/${regionId}/cities`,
  dailyAdminToken: ({ eventUri, workshopId }) => `events/${eventUri}/workshops/${workshopId}/daily-admin-token`,
  login: ({ eventUri }) => `events/${eventUri}/app-auth?source=sponsor-editor`,
  accessToken: ({ eventUri }) => `events/${eventUri}/app-access-token`,
  sponsor: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}?include_file_category=true&include_representatives=true&include=category,sponsor_representatives.attendee`,
  toggleRepresentative: ({ eventUri, sponsorId, representativeId }) => `events/${eventUri}/sponsors/${sponsorId}/sponsor_representatives/${representativeId}`,
  attendeeByEmail: ({ eventUri, email }) => `events/${eventUri}/attendees/by_email?email=${email}`,
  registerByEmail: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/sponsor_representatives/`,
  sponsorRefresh: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}?include_file_category=true&include_representatives=true&include=category,sponsor_representatives.attendee`,
  updateSponsor: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}`,
  createRepresentative: ({ eventUri }) => `events/${eventUri}/attendees/register`,
  updateRepresentative: ({ eventUri, attendeeId }) => `events/${eventUri}/attendees/${attendeeId}`,
  deleteRepresentative: ({ eventUri, attendeeId }) => `events/${eventUri}/attendees/${attendeeId}`,
  sponsorProducts: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/sponsor_products`,
  createProduct: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/sponsor_products`,
  updateProduct: ({ eventUri, sponsorId, productId }) => `events/${eventUri}/sponsors/${sponsorId}/sponsor_products/${productId}`,
  deleteProduct: ({ eventUri, sponsorId, productId }) => `events/${eventUri}/sponsors/${sponsorId}/sponsor_products/${productId}`,
  currentAttendee: ({ eventUri, id }) => `events/${eventUri}/attendees/${id}?offset_to_utc=true`,
  currentEvent: ({ eventUri }) => `events/${eventUri}`,
  updateLogo: ({ eventUri }) => `events/${eventUri}`,
  attendees: ({ eventUri }) => `events/${eventUri}/attendees?order=last_name`,
  createActivity: ({ eventUri }) => `events/${eventUri}/workshops`,
  deleteActivity: ({ eventUri, workshopId }) => `events/${eventUri}/workshops/${workshopId}`,
  editActivity: ({ eventUri, workshopId }) => `events/${eventUri}/workshops/${workshopId}`,
  workshops: ({ eventUri, sponsorId }) => `events/${eventUri}/workshops?offset_to_utc=true${sponsorId ? `&sponsor_id=${sponsorId}` : ''}`,
  meetings: ({ eventUri, businessConferenceId, participantId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/agenda?offset_to_utc=true`,
  tags: ({ eventUri }) => `events/${eventUri}/tags`,
  sponsorsFiles: ({ eventUri, fileCategoryId, sponsorId }) => `events/${eventUri}/event_file_categories/${fileCategoryId}/event_files?sponsor_id=${sponsorId}`,
  dashboardData: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/stats`,
  attendeeEventTraces: ({ eventUri, sponsorId }) => `events/${eventUri}/attendee_event_traces?has_meta_data=true&include=attendee&page[size]=24&sponsor_id=${sponsorId}`,
  activitiesAttendeesList: ({ eventUri, workshopId, keywords }) => `events/${eventUri}/workshops/${workshopId}/attendees?page[size]=24&workshop_id=${workshopId}&order=first_name${keywords ? `&key_words=${keywords}` : ''}`,
  downloadRegisteredAttendees: ({ eventUri, workshopId }) => `events/${eventUri}/workshops/${workshopId}/attendees/download`,
  downloadAttendeeEventTraces: ({ eventUri }) => `events/${eventUri}/attendee_event_traces/download`,
  createSponsorsFiles: ({ eventUri, fileCategoryId }) => `events/${eventUri}/event_file_categories/${fileCategoryId}/event_files`,
  deleteSponsorsFiles: ({ eventUri, fileCategoryId, fileId }) => `events/${eventUri}/event_file_categories/${fileCategoryId}/event_files/${fileId}`,
  hotspots: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/hotspots`,
  createHotspot: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/hotspots`,
  updateHotspot: ({ eventUri, sponsorId, hotspotId }) => `events/${eventUri}/sponsors/${sponsorId}/hotspots/${hotspotId}`,
  deleteHotspot: ({ eventUri, sponsorId, hotspotId }) => `events/${eventUri}/sponsors/${sponsorId}/hotspots/${hotspotId}`,
};

const appendFilters = (endpoint, filters) => {
  if (!filters || Object.keys(filters).length === 0) return endpoint;

  const connector = endpoint.includes('?') ? '&' : '?';
  const query = Object.keys(filters)
    .filter((f) => (typeof filters[f] !== 'undefined' && filters[f] !== null))
    .map((filter) => `${encodeURIComponent(humps.decamelize(filter))}=${encodeURIComponent(filters[filter])}`)
    .join('&');

  return `${endpoint}${connector}${query}`;
};

export default (name, params, filters) => {
  const locale = window.location.pathname.split('/')[1] || 'en';
  const endpoint = typeof endpoints[name] === 'function' ? endpoints[name](params) : endpoints[name];
  const endpointWithFilters = appendFilters(endpoint, filters);
  // const defaultHost = 'http://localhost:3002';
  // const defaultHost = 'https://dev.eventtia.com';
  // const defaultHost = 'https://stage.eventtia.com';
  const defaultHost = 'https://connect.eventtia.com';
  return `${process.env.REACT_APP_EVENTTIA_HOST || defaultHost}/${locale}/api/v3/${endpointWithFilters}`;
};
