import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

export default (state = {}, action) => {
  if (!action?.response?.entities) return state;

  const newEntities = action.response.entities;
  let newState;

  if (action.method === 'DELETE') {
    newState = cloneDeep(state);

    Object.keys(newEntities).forEach((entityType) => (
      Object.keys(newEntities[entityType]).forEach((id) => delete newState[entityType][id])
    ));
  } else newState = merge({}, state, newEntities);

  if (action.type === 'UPDATE_SPONSOR_SUCCESS') {
    const sponsorId = Object.keys(newState.sponsors);
    newState.sponsors[sponsorId].tags = newEntities.sponsors[sponsorId].tags;
    newState.sponsors[sponsorId].mediaInfo = newEntities.sponsors[sponsorId].mediaInfo;
  }

  if (typeof action.additionalReduction === 'function') newState = action.additionalReduction(newState);

  return newState;
};
