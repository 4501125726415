import merge from 'lodash/merge';
import moment, { baseMoment } from '../../helpers/dates';
import getEndpoint from '../../helpers/endpoints';
import { getToken } from '../../helpers/auth';
import constants from '../../helpers/constants';
import { getEventUri } from '../../helpers/getters';

const { EVENTS } = constants;

export const CALL_API = 'Call API';

export const fetchConfig = {
  login: {
    types: ['LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_FAILURE'],
    options: ({ email, accessToken }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { access_token: accessToken, email, source: 'sponsor-editor' },
    }),
  },
  attendeeTypes: {
    types: ['ATTENDEE_TYPES_REQUEST', 'ATTENDEE_TYPES_SUCCESS', 'ATTENDEE_TYPES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  activitiesAttendeesList: {
    types: ['REGISTERED_ATTENDEES_LIST_REQUEST', 'REGISTERED_ATTENDEES_LIST_SUCCESS', 'REGISTERED_ATTENDEES_LIST_FAILURE'],
  },
  dailyAdminToken: {
    types: ['DAILY_ADMIN_REQUEST', 'DAILY_ADMIN_SUCCESS', 'DAILY_ADMIN_FAILURE'],
    options: ({ username }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { username },
    }),
  },
  accessToken: {
    types: ['ACCESS_TOKEN_REQUEST', 'ACCESS_TOKEN_SUCCESS', 'ACCESS_TOKEN_FAILURE'],
    options: ({ email }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { email },
    }),
  },
  currentAttendee: {
    types: [
      'CURRENT_ATTENDEE_REQUEST',
      'CURRENT_ATTENDEE_SUCCESS',
      'CURRENT_ATTENDEE_FAILURE',
    ],
  },
  attendees: {
    types: ['ATTENDEES_REQUEST', 'ATTENDEES_SUCCESS', 'ATTENDEES_FAILURE'],
    filters: {
      status: { clearable: false },
      search: { clearable: false },
    },
  },
  createRepresentative: {
    types: ['CREATE_REPRESENTATIVE_REQUEST', 'CREATE_REPRESENTATIVE_SUCCESS', 'CREATE_REPRESENTATIVE_FAILURE'],
    options: ({ data }) => {
      data.append('offsetToUtc', true);
      return {
        headers: {
          Accept: '*/*',
        },
        method: 'POST',
        body: data,
      };
    },
  },
  toggleRepresentative: {
    types: ['TOGGLE_REPRESENTATIVE_REQUEST', 'TOGGLE_REPRESENTATIVE_SUCCESS', 'TOGGLE_REPRESENTATIVE_FAILURE'],
    options: ({ data }) => (
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: data,
      }
    ),
  },
  registerByEmail: {
    types: ['REGISTER_BY_EMAIL_REQUEST', 'REGISTER_BY_EMAIL_SUCCESS', 'REGISTER_BY_EMAIL_FAILURE'],
    options: ({ data }) => (
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: data,
      }
    ),
  },
  attendeeByEmail: {
    types: [
      'ATTENDEE_BY_EMAIL_REQUEST',
      'ATTENDEE_BY_EMAIL_SUCCESS',
      'ATTENDEE_BY_EMAIL_FAILURE',
    ],
  },
  updateRepresentative: {
    types: ['UPDATE_REPRESENTATIVE_REQUEST', 'UPDATE_REPRESENTATIVE_SUCCESS', 'UPDATE_REPRESENTATIVE_FAILURE'],
    options: ({ data }) => {
      data.append('offsetToUtc', true);
      return {
        headers: {
          Accept: '*/*',
        },
        method: 'PATCH',
        body: data,
      };
    },
  },
  deleteRepresentative: {
    types: ['DELETE_REPRESENTATIVE_REQUEST', 'DELETE_REPRESENTATIVE_SUCCESS', 'DELETE_REPRESENTATIVE_FAILURE'],
    options: () => ({
      headers: {
        Accept: '*/*',
      },
      method: 'DELETE',
    }),
    passToAction: ({ attendeeId }) => ({
      additionalReduction: (entities) => {
        const { sponsorRepresentatives, sponsors } = entities;
        const currentSponsor = Object.values(sponsors || {})?.[0];
        if (sponsorRepresentatives) Object.keys(sponsorRepresentatives).forEach((sRepId) => {
          const { attendee: { id } } = sponsorRepresentatives[sRepId];
          if (id === attendeeId) {
            delete sponsorRepresentatives[sRepId];
            if (currentSponsor) currentSponsor
              .sponsorRepresentatives = currentSponsor.sponsorRepresentatives
                .filter(({ id: repId }) => repId !== sRepId);
          }
        });
        return entities;
      },
    }),
  },
  currentEvent: {
    types: ['CURRENT_EVENT_REQUEST', 'CURRENT_EVENT_SUCCESS', 'CURRENT_EVENT_FAILURE'],
    auth: false,
  },
  meetings: {
    types: ['MEETINGS_REQUEST', 'MEETINGS_SUCCESS', 'MEETINGS_FAILURE'],
    fetchAllPages: true,
  },
  events: {
    types: ['EVENTS_REQUEST', 'EVENTS_SUCCESS', 'EVENTS_FAILURE'],
    filters: {
      status: { clearable: false },
      search: { clearable: false },
      datesRange: { type: 'StartAndEndDate' },
    },
    parseFilters: ({ datesRange, status, search, ...filters }) => {
      const result = {
        ...filters,
        name: search,
        startDateUpperBound: datesRange?.startDate?.upper || undefined,
        startDateLowerBound: datesRange?.startDate?.lower || undefined,
        endDateUpperBound: datesRange?.endDate?.upper || undefined,
        endDateLowerBound: datesRange?.endDate?.lower || undefined,
      };
      switch (status) {
        case EVENTS.CATEGORIES.TEMPLATES:
          result.templates = 'true';
          break;
        case EVENTS.CATEGORIES.PAST:
          result.endDateUpperBound = baseMoment.min(
            moment(),
            moment(result.endDateUpperBound)
          ).format();
          result.order = 'end_date desc';
          break;
        case EVENTS.CATEGORIES.CURRENT:
        default:
          result.endDateLowerBound = baseMoment.max(
            moment(),
            moment(result.endDateLowerBound)
          ).format();
          result.order = 'start_date asc';
      }
      return result;
    },
  },
  countries: {
    types: ['COUNTRIES_REQUEST', 'COUNTRIES_SUCCESS', 'COUNTRIES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  regions: {
    types: ['REGIONS_REQUEST', 'REGIONS_SUCCESS', 'REGIONS_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  cities: {
    types: ['CITIES_REQUEST', 'CITIES_SUCCESS', 'CITIES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  updateLogo: {
    types: [
      'UPDATE_LOGO_REQUEST',
      'UPDATE_LOGO_SUCCESS',
      'UPDATE_LOGO_FAILURE',
    ],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body,
    }),
  },
  sponsor: {
    types: ['SPONSOR_REQUEST', 'SPONSOR_SUCCESS', 'SPONSOR_FAILURE'],
  },
  sponsorRefresh: {
    types: ['SPONSOR_REFRESH_REQUEST', 'SPONSOR_REFRESH_SUCCESS', 'SPONSOR_REFRESH_FAILURE'],
  },
  sponsorProducts: {
    types: ['SPONSOR_PRODUCTS_REQUEST', 'SPONSOR_PRODUCTS_SUCCESS', 'SPONSOR_PRODUCTS_FAILURE'],
  },
  createProduct: {
    types: ['CREATE_PRODUCT_REQUEST', 'CREATE_PRODUCT_SUCCESS', 'CREATE_PRODUCT_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'POST',
      body: data,
    }),
  },
  createActivity: {
    types: ['CREATE_ACTIVITY_REQUEST', 'CREATE_ACTIVITY_SUCCESS', 'CREATE_ACTIVITY_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'POST',
      body: data,
    }),
  },
  updateProduct: {
    types: ['UPDATE_PRODUCT_REQUEST', 'UPDATE_PRODUCT_SUCCESS', 'UPDATE_PRODUCT_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'PATCH',
      body: data,
    }),
  },
  editActivity: {
    types: ['EDIT_ACTIVITY_REQUEST', 'EDIT_ACTIVITY_SUCCESS', 'EDIT_ACTIVITY_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'PATCH',
      body: data,
    }),
  },
  deleteProduct: {
    types: ['DELETE_PRODUCT_REQUEST', 'DELETE_PRODUCT_SUCCESS', 'DELETE_PRODUCT_FAILURE'],
    options: () => ({
      headers: {
        Accept: '*/*',
      },
      method: 'DELETE',
    }),
  },
  deleteActivity: {
    types: ['DELETE_ACTIVITY_REQUEST', 'DELETE_ACTIVITY_SUCCESS', 'DELETE_ACTIVITY_FAILURE'],
    options: () => ({
      headers: {
        Accept: '*/*',
      },
      method: 'DELETE',
    }),
  },
  workshops: {
    types: ['WORKSHOPS_REQUEST', 'WORKSHOPS_SUCCESS', 'WORKSHOPS_FAILURE'],
    fetchAllPages: true,
  },
  tags: {
    types: ['TAGS_REQUEST', 'TAGS_SUCCESS', 'TAGS_FAILURE'],
    fetchAllPages: true,
  },
  updateSponsor: {
    types: ['UPDATE_SPONSOR_REQUEST', 'UPDATE_SPONSOR_SUCCESS', 'UPDATE_SPONSOR_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'PATCH',
      body: data,
    }),
  },
  sponsorsFiles: {
    types: ['SPONSORS_FILES_REQUEST', 'SPONSORS_FILES_SUCCESS', 'SPONSORS_FILES_FAILURE'],
    fetchAllPages: true,
  },
  createSponsorsFiles: {
    types: ['CREATE_SPONSOR_FILE_REQUEST', 'CREATE_SPONSOR_FILE_SUCCESS', 'CREATE_SPONSOR_FILE_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'POST',
      body: data,
    }),
  },
  dashboardData: {
    types: ['DASHBOARD_DATA_REQUEST', 'DASHBOARD_DATA_SUCCESS', 'DASHBOARD_DATA_FAILURE'],
  },
  attendeeEventTraces: {
    types: ['ATTENDEE_TRACES_REQUEST', 'ATTENDEE_TRACES_SUCCESS', 'ATTENDEE_TRACES_FAILURE'],
  },
  downloadAttendeeEventTraces: {
    types: ['DOWNLOAD_ATTENDEE_TRACES_REQUEST',
      'DOWNLOAD_ATTENDEE_TRACES_SUCCESS', 'DOWNLOAD_ATTENDEE_TRACES_FAILURE'],
  },
  downloadRegisteredAttendees: {
    types: ['DOWNLOAD_REGISTERED_ATTENDEES_REQUEST',
      'DOWNLOAD_REGISTERED_ATTENDEES_SUCCESS', 'DOWNLOAD_REGISTERED_ATTENDEES_FAILURE'],
  },
  deleteSponsorsFiles: {
    types: ['DELETE_SPONSOR_FILE_REQUEST', 'DELETE_SPONSOR_FILE_SUCCESS', 'DELETE_SPONSOR_FILE_FAILURE'],
    options: () => ({
      headers: {
        Accept: '*/*',
      },
      method: 'DELETE',
    }),
  },
  hotspots: {
    types: ['HOTSPOTS_REQUEST', 'HOTSPOTS_SUCCESS', 'HOTSPOTS_FAILURE'],
  },
  createHotspot: {
    types: ['CREATE_HOTSPOT_REQUEST', 'CREATE_HOTSPOT_SUCCESS', 'CREATE_HOTSPOT_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'POST',
      body: data,
    }),
  },
  updateHotspot: {
    types: ['UPDATE_HOTSPOT_REQUEST', 'UPDATE_HOTSPOT_SUCCESS', 'UPDATE_HOTSPOT_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'PATCH',
      body: data,
    }),
  },
  deleteHotspot: {
    types: ['DELETE_HOTSPOT_REQUEST', 'DELETE_HOTSPOT_SUCCESS', 'DELETE_HOTSPOT_FAILURE'],
    options: () => ({
      headers: {
        Accept: '*/*',
      },
      method: 'DELETE',
    }),
  },
};

const getDefaultOptions = (eventUri, { auth = true }) => {
  const token = getToken(eventUri);
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
    },
  };
  if (token && auth) defaultOptions.headers.Authorization = `bearer ${token}`;
  return defaultOptions;
};

export default (key, params, page) => (dispatch, getState) => {
  const config = fetchConfig[key];
  const [, successType] = config.types;
  const filters = getState().filters[key];

  // return success if fetch should be skipped
  if (typeof config.skip === 'function' && config.skip(params)) return { type: successType };

  const filterParams = config.parseFilters ? config.parseFilters(filters) : filters;
  if (typeof page === 'number') filterParams['page[number]'] = page;

  let { options } = config;
  if (typeof options === 'function') options = options(params);

  const requestDetails = {
    types: config.types,
    endpoint: (config.fetchAllPages || typeof page !== 'string')
      ? getEndpoint(key, params, filterParams)
      : page,
    passToAction: config.passToAction || {},
    options: merge(getDefaultOptions(getEventUri(), config), options),
  };

  if (config.fetchAllPages) requestDetails.fetchAllPages = true;
  else if (typeof page === 'string') requestDetails.passToAction.extendOrder = true;

  return dispatch({
    [CALL_API]: requestDetails,
  });
};
