export default {
  en: {
    actions: {
      add: 'Add',
      addToCalendar: 'Add to calendar',
      accept: 'Accept',
      addFile: 'Add file',
      addImage: 'Add image',
      clear: 'Clear',
      clone: 'Clone',
      create: 'Create',
      join: 'Join',
      joinStream: 'Join stream',
      delete: 'Delete',
      edit: 'Edit',
      filter: 'Filter',
      hideFilters: 'Hide filters',
      hideReactions: 'Disable Emotions',
      showReactions: 'Enable Emotions',
      remove: 'Remove',
      saveChanges: 'Save changes',
      save: 'Save',
      search: 'Search',
      cancel: 'Cancel',
      moreOptions: 'More options',
      logout: 'Log out',
      editPhoto: 'Edit Photo',
      send: 'Send',
      upload: 'Upload',
    },
    error404: {
      title: 'We couldnt find the page you were looking for',
      subtitle: 'Try another option using the sidebar.',
    },
    title: {
      announcement: 'Announcement!',
      reminder: 'Reminder',
    },
    formError: {
      enterValidEmail: 'Enter a valid email.',
      requiredField: 'The field is required.',
      invalidToken: 'The token you entered is invalid.',
      invalidEmail: 'The email you provided is invalid.',
      minLength: 'Minimum 4 characters',
      maxLength: 'Maximum 500 characters',
    },
    forms: {
      notRegisteredLink: ' Register here',
      signature: 'Signature',
      name: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      description: 'Description',
      telephone: 'Phone',
      country: 'Country',
      birthdate: 'Birthdate',
      timezone: 'Timezone',
      alternativeEmail: 'Alternative email',
      headshot: 'Headshot',
      jobTitle: 'Job title',
      company: 'Company',
      region: 'Region',
      city: 'City',
      photo: 'Photo',
      ticketType: 'Ticket type',
      searchKeyword: 'Keywords',
      email: 'Email',
      accessToken: 'Access token',
      validateToken: 'Validate token',
      logIn: 'Log in',
      error: 'Error',
      registration: 'Registration',
      limitRegistration: 'Limit registration',
      limitAttendees: 'Maximum number of attendees',
    },
    filters: {
      apply: 'Apply filters',
      clear: 'Clear filters',
      title: 'Filters',
      categories: {
        categoriesTypes: 'Categories types',
        all: 'All',
      },
      events: {
        datesRange: 'Date Range',
      },
      sort: {
        sortBy: 'Sort by',
        'first_name asc': 'Name A-Z↓',
        'first_name desc': 'Name Z-A↓',
        'last_name asc': 'Last name A-Z↓',
        'last_name desc': 'Last name Z-A↓',
        'company asc': 'Company A-Z↓',
        'company desc': 'Company Z-A↓',
      },
    },
    dates: {
      localTimeIs: 'Local time is',
      startDate: 'Start date',
      endDate: 'End date',
      range: 'Date range',
      from: 'From',
      to: 'To',
    },
    languages: {
      en: 'English',
      es: 'Spanish',
      fr: 'French',
    },
    status: {
      title: 'Status',
      saving: 'Saving',
      completed: 'Completed',
    },
    info: {
      logoAspectRatio: 'For a better display, make sure the logo has a 16:9 aspect ratio',
    },
    speakers: {
      speakers: 'Speakers',
    },
    sponsors: {
      sponsoredBy: 'Sponsored by',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'This meeting hasn’t started yet!\nTake a seat and relax, you have {{time}} before it begins.',
      ended: 'Your meeting with {{otherParticipant}} ended {{when}}',
      remaining: 'remaining',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'at',
    },
    qa: {
      sendAQuestion: 'Send a question...',
    },
    confirmation: {
      yes: 'Yes',
    },
    reactions: {
      reactions: 'Reactions',
    },
    sponsorProfileEditor: 'Showcase editor',
  },
  es: {
    actions: {
      add: 'Agregar',
      addToCalendar: 'Agregar al calendario',
      accept: 'Aceptar',
      addFile: 'Agregar un archivo',
      addImage: 'Agregar una imagen',
      clear: 'Limpiar',
      clone: 'Clonar',
      create: 'Crear',
      join: 'Unirse',
      joinStream: 'Unirse al stream',
      delete: 'Eliminar',
      edit: 'Editar',
      filter: 'Filtrar',
      hideFilters: 'Ocultar filtros',
      hideReactions: 'Deshabilitar emociones',
      showReactions: 'Habilitar emociones',
      remove: 'Eliminar',
      saveChanges: 'Guardar cambios',
      save: 'Guardar',
      search: 'Buscar',
      cancel: 'Cancelar',
      moreOptions: 'Más opciones',
      logout: 'Cerrar sesión',
      editPhoto: 'Editar foto',
      send: 'Enviar',
      upload: 'Subir',
    },
    error404: {
      title: 'No encontramos la página que tratas de acceder.',
      subtitle: 'Intenta otra opción en la barra lateral.',
    },
    title: {
      announcement: '¡Anuncio!',
      reminder: 'Recordatorio',
    },
    formError: {
      enterValidEmail: 'Ingresa un email válido',
      requiredField: 'El campo es obligatorio.',
      invalidToken: 'El token que has ingresado es inválido.',
      invalidEmail: 'El correo ingresado es inválido.',
      minLength: 'Mínimo 4 caracteres',
      maxLength: 'Máximo 500 caracteres',
    },
    forms: {
      notRegisteredLink: ' Regístrate aquí',
      signature: 'Firma',
      name: 'Nombre',
      firstName: 'Nombre',
      lastName: 'Apellido',
      description: 'Description',
      telephone: 'Teléfono',
      country: 'Pais',
      birthdate: 'Fecha de nacimiento',
      timezone: 'Zona horaria',
      alternativeEmail: 'Email alternativo',
      headshot: 'Foto',
      jobTitle: 'Titulo laboral',
      company: 'Compañía',
      region: 'Región',
      city: 'Ciudad',
      photo: 'Foto',
      ticketType: 'Tipo de tiquete',
      searchKeyword: 'Palabras clave',
      email: 'Correo',
      accessToken: 'Código de acceso',
      validateToken: 'Validar token',
      logIn: 'Ingresar',
      error: 'Error',
      registration: 'Registro',
      limitRegistration: 'Limitar registro',
      limitAttendees: 'Maximo numero de participantes',
    },
    filters: {
      apply: 'Aplicar filtros',
      clear: 'Borrar filtros',
      title: 'Filtros',
      categories: {
        categoriesTypes: 'Tipos de categorias',
        all: 'Todas',
      },
      events: {
        datesRange: 'Rango de Fechas',
      },
      sort: {
        sortBy: 'Ordenar por',
        'first_name asc': 'Nombre A-Z↓',
        'first_name desc': 'Nombre Z-A↓',
        'last_name asc': 'Apellido A-Z↓',
        'last_name desc': 'Apellido Z-A↓',
        'company asc': 'Compañia A-Z↓',
        'company desc': 'Compañia Z-A↓',
      },
    },
    dates: {
      localTimeIs: 'La hora local es',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      range: 'Rango de fechas',
      from: 'Desde',
      to: 'Hasta',
    },
    languages: {
      en: 'Inglés',
      es: 'Español',
      fr: 'Frances',
    },
    status: {
      title: 'Estado',
      saving: 'Guardando',
      completed: 'Completada',
    },
    info: {
      logoAspectRatio: 'Para una mejor visualización asegúrate que el logo tenga una proporción de 16:9',
    },
    speakers: {
      speakers: 'Ponentes',
    },
    sponsors: {
      sponsoredBy: 'Patrocinado por',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'Ésta reunión aún no ha comenzado!\nToma asiento y relájate, tienes {{time}} antes de que comience',
      ended: 'Tu reunión con {{otherParticipant}} terminó {{when}}',
      remaining: 'restante',
    },
    notes: {
      title: 'Notas',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'en',
    },
    qa: {
      sendAQuestion: 'Envia una pregunta...',
    },
    confirmation: {
      yes: 'Si',
    },
    reactions: {
      reactions: 'Reacciones',
    },
    sponsorProfileEditor: 'Editor de perfil',
  },
  fr: {
    actions: {
      add: 'Ajouter',
      addToCalendar: 'Ajouter au calendrier',
      accept: 'Accepter',
      addFile: 'Ajouter un fichier',
      addImage: 'Ajouter une image',
      clear: 'Effacer',
      clone: 'Cloner',
      create: 'Créer',
      join: 'Rejoindre',
      joinStream: 'Rejoindre le streaming',
      delete: 'Supprimer',
      edit: 'Éditer',
      filter: 'Filtrer',
      hideFilters: 'Cacher les filtres',
      hideReactions: 'Désactiver l\'émotion',
      showReactions: 'Permettre les émotions',
      remove: 'Éliminer',
      saveChanges: 'Sauvegarder les changements',
      save: 'Sauvegarder',
      search: 'Rechercher',
      cancel: 'Annuler',
      moreOptions: 'Plus d\'options',
      logout: 'Se déconnecter',
      editPhoto: 'Éditer la photo',
      send: 'Envoyer',
      upload: 'Télécharger',
    },
    error404: {
      title: 'Nous n\'avons pas trouvé la page que vous recherchiez',
      subtitle: 'Essayez une autre option en utilisant la barre latérale.',
    },
    title: {
      announcement: 'Annonce!',
      reminder: 'Rappel',
    },
    formError: {
      enterValidEmail: 'Entrez un courriel valide',
      requiredField: 'Le champ est obligatoire.',
      invalidToken: 'Le jeton que vous avez saisi n\'est pas valable.',
      invalidEmail: 'Le courrier entré n\'est pas valide',
      minLength: 'Minimum 4 caractères',
      maxLength: 'Maximum 500 caractères',
    },
    forms: {
      notRegisteredLink: ' Inscrivez-vous ici',
      signature: 'Signature',
      name: 'Nom',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      description: 'Description',
      telephone: 'Téléphone',
      country: 'Pays',
      birthdate: 'Date de naissance',
      timezone: 'Fuseau horaire',
      alternativeEmail: 'Email alternatif',
      headshot: 'photo d\'identité',
      jobTitle: 'Fonction',
      company: 'Enterprise',
      region: 'Région',
      city: 'Cité',
      photo: 'Photo',
      ticketType: 'Type de billet',
      searchKeyword: 'Mots clés',
      email: 'Email',
      accessToken: 'Code d\'accès',
      validateToken: 'Valider le code',
      logIn: 'Se connecter',
      error: 'Erreur',
      registration: 'Enregistrement',
      limitRegistration: 'Limiter l\'inscription',
      limitAttendees: 'Nombre maximum de participants',
    },
    filters: {
      apply: 'Appliquer les filtres',
      clear: 'Effacer les filtres',
      title: 'Filtres',
      categories: {
        categoriesTypes: 'Type de catégories',
        all: 'Tous',
      },
      events: {
        datesRange: 'Plage de dates ',
      },
      sort: {
        sortBy: 'Trier par',
        'first_name asc': 'Prénom A-Z↓',
        'first_name desc': 'Prénom Z-A↓',
        'last_name asc': 'Nom A-Z↓',
        'last_name desc': 'Nom Z-A↓',
        'company asc': 'Entreprise A-Z↓',
        'company desc': 'Entreprise Z-A↓',
      },
    },
    dates: {
      localTimeIs: 'L\'heure locale est',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      range: 'Plage de dates',
      from: 'de',
      to: 'à',
    },
    languages: {
      en: 'Anglais',
      es: 'Espagnol',
      fr: 'Français',
    },
    status: {
      title: 'Statut',
      saving: 'Sauver',
      completed: 'Complété',
    },
    info: {
      logoAspectRatio: 'Pour une meilleure visualisation, assurez-vous que le logo a un rapport de 16:9',
    },
    speakers: {
      speakers: 'Intervenants',
    },
    sponsors: {
      sponsoredBy: 'Sponsorisé par',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'Cette réunion n\'a pas encore commencé!\nDétendez-vous, vous avez {{time}} avant de commencer',
      ended: 'Votre réunion avec {{otherParticipant}} s\'est terminée {{when}}',
      remaining: 'restant',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'chez',
    },
    qa: {
      sendAQuestion: 'Envoyer une question...',
    },
    confirmation: {
      yes: 'Oui',
    },
    reactions: {
      reactions: 'Réactions',
    },
    sponsorProfileEditor: 'Editeur de profil',
  },
  de: {
    actions: {
      add: 'Hinzufügen',
      addToCalendar: 'Zum Kalender hinzufügen',
      accept: 'Akzeptieren',
      addFile: 'Datei hinzufügen',
      addImage: 'Bild hinzufügen',
      clear: 'Klar',
      clone: 'Klon',
      create: 'Erstellen',
      join: 'Treten Sie bei',
      joinStream: 'Stream beitreten',
      delete: 'Löschen',
      edit: 'Bearbeiten',
      filter: 'Filter',
      hideFilters: 'Filter ausblenden',
      hideReactions: 'Emotionen deaktivieren',
      showReactions: 'Emotionen aktivieren',
      remove: 'Entfernen',
      saveChanges: 'Änderungen speichern',
      save: 'Speichern',
      search: 'Suchen',
      cancel: 'Stornieren',
      moreOptions: 'Mehr Optionen',
      logout: 'Abmelden',
      editPhoto: 'Foto bearbeiten',
      send: 'Senden',
      upload: 'Upload',
    },
    error404: {
      title: 'Wir konnten die Seite, nach der Sie gesucht haben, nicht finden.',
      subtitle: 'Versuchen Sie es mit einer anderen Option über die Seitenleiste.',
    },
    title: {
      announcement: 'Ankündigung!',
      reminder: 'Erinnerung',
    },
    formError: {
      enterValidEmail: 'Geben Sie eine gültige E-Mail ein.',
      requiredField: 'Das Feld ist erforderlich.',
      invalidToken: 'Das von Ihnen eingegebene Token ist ungültig.',
      invalidEmail: 'Die von Ihnen angegebene E-Mail ist ungültig.',
      minLength: 'Mindestens 4 Zeichen',
      maxLength: 'Maximal 500 Zeichen',
    },
    forms: {
      notRegisteredLink: ' Registrieren Sie sich hier',
      signature: 'Unterschrift',
      name: 'Name',
      firstName: 'Vorname',
      lastName: 'Nachname',
      description: 'Beschreibung',
      telephone: 'Telefon',
      country: 'Land',
      birthdate: 'Geburtsdatum',
      timezone: 'Zeitzone',
      alternativeEmail: 'Alternative E-Mail',
      headshot: 'Headshot',
      jobTitle: 'Berufsbezeichnung',
      company: 'Firma',
      region: 'Region',
      city: 'Stadt',
      photo: 'Foto',
      ticketType: 'Art des Tickets',
      searchKeyword: 'Stichwörter',
      email: 'E-Mail',
      accessToken: 'Zugriffstoken',
      validateToken: 'Token validieren',
      logIn: 'Loggen Sie sich ein',
      error: 'Fehler',
      registration: 'Registrierung',
      limitRegistration: 'Registrierung einschränken',
      limitAttendees: 'Maximale Anzahl von Teilnehmern',
    },
    filters: {
      apply: 'Filter anwenden',
      clear: 'Filter löschen',
      title: 'Filter',
      categories: {
        categoriesTypes: 'Kategorien, Typen',
        all: 'Alles',
      },
      events: {
        datesRange: 'Datumsbereich',
      },
      sort: {
        sortBy: 'Sortieren nach',
        'first_name asc': 'Name A-Z↓',
        'first_name desc': 'Nome Z-A↓',
        'last_name asc': 'Nachname A-Z↓',
        'last_name desc': 'Nachname Z-A↓',
        'company asc': 'Firma A-Z↓',
        'company desc': 'Firma Z-A↓',
      },
    },
    dates: {
      localTimeIs: 'Ortszeit ist',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      from: 'Von',
      to: 'Zu',
    },
    languages: {
      en: 'Englisch',
      es: 'Spanisch',
      fr: 'Französisch',
    },
    status: {
      title: 'Status',
      saving: 'Speichern',
      completed: 'Abgeschlossen',
    },
    info: {
      logoAspectRatio: 'Für eine bessere Darstellung stellen Sie sicher, dass das Logo ein Seitenverhältnis von 16:9 hat',
    },
    speakers: {
      speakers: 'Lautsprecher',
    },
    sponsors: {
      sponsoredBy: 'Gesponsert von',
      poweredBy: 'Bereitgestellt von',
    },
    timer: {
      before: 'Dieses Treffen hat noch nicht begonnen!\nNehmen Sie Platz und entspannen Sie sich, Sie haben {{time}} bevor es beginnt.',
      ended: 'Ihr Treffen mit {{otherParticipant}} endete {{when}}',
      remaining: 'verbleibend',
    },
    notes: {
      title: 'Notizen',
    },
    chat: {
      title: 'Chatten',
    },
    prepositions: {
      at: 'bei',
    },
    qa: {
      sendAQuestion: 'Senden Sie eine Frage...',
    },
    confirmation: {
      yes: 'Ja',
    },
    reactions: {
      reactions: 'Reaktionen',
    },
    sponsorProfileEditor: 'Showcase-Redakteur',
  },
};
