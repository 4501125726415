export default {
  en: {
    addVideo: 'Add video content to your sponsor page to share information with the event’s attendees. You can add up to 10 videos!',
    embeddedVideo: 'Embedded video',
    addAnotherVideo: 'Add another video',
    saveChanges: 'Save changes',
    deleteVideo: 'Remove video?',
    delete: 'Remove video',
    deleteText: 'Are you sure you want to remove this video?',
    errorMessage: 'This textbox only allows embedded videos',
    videoUpdated: 'Video updated!',
    videoUpdatedDescription: 'Your video was successfully updated',
    videoUpdateError: 'Update error',
    videoUpdateErrorDescription: 'There was an error updating your video',
    maxVideoError: 'Maximum video error',
    maxVideoErrorDescription: 'You already reached the maximum number of videos that you are able to upload (10 videos)',
    videoEmpty: 'Video is empty.',
    videoEmptyDescription: 'You can\'t save an empty video.',
  },
  es: {
    addVideo: 'Agrega videos a tu página de patrocinador para compartir información con los participantes del evento. ¡Puedes agregar hasta 10 videos!',
    embeddedVideo: 'Video embebido',
    addAnotherVideo: 'Agregar otro video',
    saveChanges: 'Guardar cambios',
    deleteVideo: 'Borrar video?',
    delete: 'Borrar video',
    deleteText: 'Estas seguro de querer borrar este video?',
    errorMessage: 'Este formulario solo recibe iframes',
    videoUpdated: 'Video actualizado!',
    videoUpdatedDescription: 'Tu video se actualizó correctamente',
    videoUpdateError: 'Error al actualizar',
    videoUpdateErrorDescription: 'Ocurrió un error al actualizar tu video',
    maxVideoError: 'Limite de videos',
    maxVideoErrorDescription: 'Has alcanzado el número máximo de videos permitidos (10 videos)',
    videoEmpty: 'Video vacío.',
    videoEmptyDescription: 'No puedes guardar un video vacío.',
  },
  fr: {
    addVideo: 'Ajoutez des vidéos à votre page de sponsor pour partager des informations avec les participants à l\'événement - vous pouvez ajouter jusqu\'à 10 vidéos !',
    embeddedVideo: 'Vidéo integrée',
    addAnotherVideo: 'Ajouter une autre vidéo',
    saveChanges: 'Sauvegarder les modifications',
    deleteVideo: 'Supprimer la vidéo ?',
    delete: 'Supprimer la vidéo',
    deleteText: 'Êtes-vous sûr de vouloir supprimer cette vidéo ?',
    errorMessage: 'Ce format ne reçoit que des iframes',
    videoUpdated: 'Vidéo mise à jour !',
    videoUpdatedDescription: 'Votre vidéo a été mise à jour avec succès',
    videoUpdateError: 'Erreur de mise à jour',
    videoUpdateErrorDescription: 'Une erreur s\'est produite lors de la mise à jour de votre vidéo',
    maxVideoError: 'Limite vidéo atteinte',
    maxVideoErrorDescription: 'Vous avez atteint le nombre maximum de vidéos autorisées (10 vidéos).',
    videoEmpty: 'La vidéo est vide.',
    videoEmptyDescription: 'Vous ne pouvez pas enregistrer une vidéo vide.',
  },
  de: {
    addVideo: 'Fügen Sie Ihrer Sponsorenseite Videoinhalte hinzu, um Informationen mit den Teilnehmern der Veranstaltung zu teilen. Sie können bis zu 10 Videos hinzufügen!',
    embeddedVideo: 'Eingebettetes Video',
    addAnotherVideo: 'Weiteres Video hinzufügen',
    saveChanges: 'Änderungen speichern',
    deleteVideo: 'Video entfernen?',
    delete: 'Video entfernen',
    deleteText: 'Sind Sie sicher, dass Sie dieses Video entfernen möchten?',
    errorMessage: 'Dieses Textfeld erlaubt nur eingebettete Videos',
    videoUpdated: 'Das Video wurde aktualisiert!',
    videoUpdatedDescription: 'Ihr Video wurde erfolgreich aktualisiert',
    videoUpdateError: 'Fehler beim Update',
    videoUpdateErrorDescription: 'Beim Aktualisieren Ihres Videos ist ein Fehler aufgetreten',
    maxVideoError: 'Maximaler Videofehler',
    maxVideoErrorDescription: 'Sie haben die maximale Anzahl an Videos, die Sie hochladen können, bereits erreicht (10 Videos)',
    videoEmpty: 'Video ist leer.',
    videoEmptyDescription: 'Sie können ein leeres Video nicht speichern.',
  },
};
