import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  useLocation, Switch, Route, Redirect, useParams,
} from 'react-router-dom';
import moment from 'moment-timezone';
import i18next from 'i18next';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
// import Loader from 'eventtia-ui-components/lib/Loader';
import {
  loggedIn, getTokenContent,
} from '../helpers/auth';
import callApi from '../actions/callApi';
import AsyncPage from '../hocs/AsyncPage';
import useRoutes from '../hooks/useRoutes'; // eslint-disable-next-line no-unused-vars
import LoggedOutLayout from '../components/LoggedOutLayout';
import LoggedInLayout from '../components/LoggedInLayout';
import CustomPropTypes from '../helpers/CustomPropTypes';
import withCurrentEvent from '../hocs/withCurrentEvent';

export const Component = ({ // eslint-disable-next-line no-unused-vars
  loggedInSuccessfully,
  callApi: dispatchCallApi, currentEvent, currentAttendeeFetched, sponsorSuccess,
}) => { // eslint-disable-next-line no-unused-vars
  const { loggedInRoutes, urlTo, pathTo } = useRoutes();
  const { pathname } = useLocation();
  const { locale } = useParams();
  const { eventUri } = currentEvent;

  useEffect(() => {
    moment.locale(locale);
    i18next.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    const id = getTokenContent(eventUri)?.attendee_id;
    if (id && sponsorSuccess) dispatchCallApi('currentAttendee', { eventUri, id });
  }, [dispatchCallApi, loggedInSuccessfully, eventUri, sponsorSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // if (!currentAttendeeFetched) return (
  //   <Loader loading variant="absolute" />
  // );
  return (
    <>
      {(loggedIn(eventUri) || currentAttendeeFetched) ? (
        <LoggedInLayout eventName={currentEvent.name} eventLogo={currentEvent.logo.small}>
          <Switch>
            {loggedInRoutes.map(({ path, component }) => (
              <Route exact path={path} key={component}>
                <AsyncPage page={component} event={currentEvent} />
              </Route>
            ))}
            <Route>
              <Redirect to={urlTo('sponsors')} />
            </Route>
          </Switch>
        </LoggedInLayout>
      ) : (
        <LoggedOutLayout eventName={currentEvent.name} eventLogo={currentEvent.logo.small}>
          <Switch>
            <Route exact path={pathTo('login')}>
              <AsyncPage
                page="Login"
                eventUri={eventUri}
                // accountId={accountId}
                // welcomeMessage={loginWelcomeMessage[locale]}
                // settings={settings}
              />
            </Route>
            <Route>
              <Redirect to={urlTo('login')} />
            </Route>
          </Switch>
        </LoggedOutLayout>
      )}
    </>
  );
};

Component.propTypes = {
  currentEvent: CustomPropTypes.event.isRequired,
  // settings: CustomPropTypes.appSettings.isRequired,
  currentAttendeeFetched: PropTypes.bool.isRequired,
  loggedInSuccessfully: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
  sponsorSuccess: PropTypes.bool,
  // entities: CustomPropTypes.entities.isRequired,
};

Component.defaultProps = {
  sponsorSuccess: false,
};

const mapStateToProps = ({
  // entities,
  fetchStatus: {
    currentAttendee: { success: currentAttendeeFetched },
    login: { success: loggedInSuccessfully },
    sponsor: { success: sponsorSuccess },
  },
}) => ({
  currentAttendeeFetched,
  loggedInSuccessfully,
  sponsorSuccess,
  // entities,
});

export default compose(
  withCurrentEvent(),
  connect(mapStateToProps, { callApi })
)(Component);
