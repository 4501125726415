import baseMoment from 'moment-timezone';
import i18n from 'i18next';

baseMoment.locale(i18n.language);

export { baseMoment };

const moment = (date) => baseMoment.parseZone(date).tz('America/Bogota', true);

export default moment;
// TODO use baseMoment instead moment when date fields support timezone param
// this format is only used in requests
export const getEventtiaDateFormat = (date, includeTime = false) => (
  moment(date).format(`DD/MM/YYYY${includeTime ? ' - HH:mm' : ''}`)
);
