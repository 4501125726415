export default {
  en: {
    dayView: 'Day view',
    listView: 'List view',
    noMeetings: 'There are no scheduled meetings yet',
    description: 'You can view all the scheduled meetings your representatives have with attendees',
    meeting: {
      with: 'Meeting with {{participant}}',
    },
  },
  es: {
    dayView: 'Vista por día',
    listView: 'Vista de lista',
    noMeetings: 'todavía no hay reuniones programadas',
    description: 'Puedes ver todas las reuniones que tus representates tienen con participantes',
    meeting: {
      with: 'Reunión con {{participant}}',
    },
  },
  fr: {
    dayView: 'Voir la journée',
    listView: 'Voir la liste',
    noMeetings: 'il n\'y a pas encore de réunions programmées',
    description: 'Vous pouvez afficher toutes les réunions planifiées de vos représentants avec les participants',
    meeting: {
      with: 'Rencontre avec {{participant}}',
    },
  },
  de: {
    dayView: 'Tagesansicht',
    listView: 'Listenansicht',
    noMeetings: 'Es gibt noch keine geplanten Treffen',
    description: 'Sie können alle geplanten Treffen Ihrer Vertreter mit Teilnehmern einsehen.',
    meeting: {
      with: 'Treffen mit {{participant}}',
    },
  },
};
