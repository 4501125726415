export default {
  en: {
    standPreview: 'Stand preview',
    standManagerDetails: 'Add your logo and slogan to the illustration of a stand to give your attendees a taste of the in-person experience.',
    sponsorLogo: 'Stand logo',
    sponsorLogoImageSuggestion: 'The suggested image size is 300 x 300px',
    slogan: 'Slogan',
    wordsRemaining: 'words remaining',
    mainBrandColor: 'Main brand color',
    brandColorHexCodePlaceholder: 'Paste color hex code',
    standImage: 'Stand image',
    standImageInstructions: 'Select one of the following images, this will be the stand shown in your sponsor page.',
    standSloganPlaceholder: 'Your Slogan Here!',
    enableStand: 'Enable stand',
    updateSuccess: {
      title: 'Stand updated!',
      message: 'Your stand was successfully updated!',
    },
    updateError: {
      title: 'Stand update error!',
      message: 'An error occured while updating your stand. Please try again.',
    },
    hotspots: {
      title: 'Hotspots',
      description: 'Click on one of the hotspot types below and drag and drop it to where you want it in the exhibition stand. You will be able to move and modify it by clicking on it.',
      hotspotName: 'Hotspot name',
      hotspotNamePlaceholder: 'Our Homepage',
      newHotspot: 'New hotspot',
      deleteHotspot: 'Delete hotspot',
      updateHotspot: 'Update hotspot',
      hotspotNotReady: 'Hotspot is not ready. Click to update',
      types: {
        url: {
          hotspot: 'Hotspot with link',
          name: 'Link',
          placeholder: 'https://www.yourpage.com',
        },
        video: {
          hotspot: 'Hotspot with video',
          name: 'Video',
          placeholder: 'Embed code here',
        },
        file: {
          hotspot: 'Hotspot with file',
          name: 'File',
          helper: 'To select a file, please upload one in the Files module first.',
        },
      },
      errors: {
        name: {
          empty: 'Please enter a name',
        },
        url: {
          empty: 'Please enter a link',
          patternMismatch: 'Please check the URL you\'ve entered',
        },
        video: {
          empty: 'Please enter a valid embed code',
          patternMismatch: 'Please check the embed code you\'ve entered',
        },
        file: {
          empty: 'Please select a file',
          noFiles: 'Please upload a file',
        },
      },
    },
  },
  es: {
    standPreview: 'Previsualizar stand',
    standManagerDetails: 'Agrega tu logo y tu eslogan a la ilustración de un stand para darle a tus participantes una muestra de la experiencia en persona.',
    sponsorLogo: 'Logo del stand',
    sponsorLogoImageSuggestion: 'El tamaño sugerido de la imagen es 300 x 300px',
    slogan: 'Eslogan',
    wordsRemaining: 'palabras restantes',
    mainBrandColor: 'Color principal de la marca',
    brandColorHexCodePlaceholder: 'Pega el código hex del color',
    standImage: 'Imagen del stand',
    standImageInstructions: 'Selecciona una de las siguientes imágenes, este será el stand que se mostrará en tu página de patrocinador.',
    standSloganPlaceholder: '¡Tu Eslogan Aquí!',
    enableStand: 'Habilitar stand',
    updateSuccess: {
      title: 'Stand actualizado!',
      message: '¡El stand fue actualizado exitosamente!',
    },
    updateError: {
      title: 'Error actualizando el stand!',
      message: 'Ha ocurrido un error actualizando tu stand. Por favor inténtalo nuevamente.',
    },
    hotspots: {
      title: 'Puntos de interés',
      description: 'Haz clic en uno de los tipos de puntos de interés que hay abajo y arrastra y sueltalo donde quieres que se ubique en el stand. Podrás moverlo y modificarlo haciendo click sobre él.',
      hotspotName: 'Nombre del punto de interés',
      hotspotNamePlaceholder: 'Nuestra página',
      newHotspot: 'Nuevo punto',
      updateHotspot: 'Actualizar',
      deleteHotspot: 'Eliminar',
      hotspotNotReady: 'Este punto no está listo. Clic para actualizarlo',
      types: {
        url: {
          hotspot: 'Punto de interés con enlace',
          name: 'Enlace',
          placeholder: 'https://www.tupagina.com',
        },
        video: {
          hotspot: 'Punto de interés con video',
          name: 'Video',
          placeholder: 'Código para embeber aquí',
        },
        file: {
          hotspot: 'Punto de interés con archivo',
          name: 'Archivo',
          helper: 'Para seleccionar un archivo, por favor primero carga uno en la sección de Archivos',
        },
      },
      errors: {
        name: {
          empty: 'Por favor ingresa un nombre',
        },
        url: {
          empty: 'Por favor ingresa un enlace',
          patternMismatch: 'Por favor revisa la URL que has ingresado',
        },
        video: {
          empty: 'Por favor ingresa un video',
          patternMismatch: 'Por favor revisa el código que has ingresado',
        },
        file: {
          empty: 'Por favor selecciona un archivo',
          noFiles: 'Por favor carga un archivo',
        },
      },
    },
  },
  fr: {
    standPreview: 'Aperçu du stand',
    standManagerDetails: 'Ajoutez votre logo et votre slogan à l\'illustration, pour que vos participants puissent avoir un aperçu réaliste du stand. ',
    sponsorLogo: 'Logo du stand',
    sponsorLogoImageSuggestion: 'La taille recommandée pour l\'image est de 300 x 300px',
    slogan: 'Slogan',
    wordsRemaining: 'mots restants',
    mainBrandColor: 'Couleur principale de la marque',
    brandColorHexCodePlaceholder: 'Collez le code couleur ici',
    standImage: 'Image du stand',
    standImageInstructions: 'Veuillez sélectionner un des modèles de stands suivants pour l\'afficher dans votre page sponsor. ',
    standSloganPlaceholder: 'Votre slogan ici !',
    enableStand: 'Activer le stand',
    updateSuccess: {
      title: 'Stand mis à jour !',
      message: 'Votre stand a été mis à jour avec succès !',
    },
    updateError: {
      title: 'Erreur de mise à jour du stand !',
      message: 'Une erreur s\'est produite lors de la mise à jour de votre stand. Veuillez réessayer.',
    },
    hotspots: {
      title: 'Hotspots',
      description: 'Cliquez sur l\'un des types de hotspot ci-dessous et faites-le glisser jusqu\'à l\'endroit où vous souhaitez le placer dans le stand d\'exposition. Vous pourrez le déplacer et le modifier en cliquant dessus.',
      hotspotName: 'Nom du hotspot',
      hotspotNamePlaceholder: 'Page d\'accueil',
      newHotspot: 'Nouveau hotspot',
      deleteHotspot: 'Effacer hotspot',
      updateHotspot: 'Mettre hotspot à jour',
      hotspotNotReady: 'Le hotspot n\'est pas prêt. Cliquez pour mettre à jour',
      types: {
        url: {
          hotspot: 'Hotspot avec lien',
          name: 'Lien',
          placeholder: 'https://www.votresite.com',
        },
        video: {
          hotspot: 'Hotspot avec vidéo',
          name: 'Vidéo',
          placeholder: 'Code d\'intégration ici',
        },
        file: {
          hotspot: 'Hotspot avec fichier',
          name: 'Fichier',
          helper: 'Pour sélectionner un fichier, veuillez d\'abord en télécharger un dans le module Fichiers.',
        },
      },
      errors: {
        name: {
          empty: 'Veuillez renseigner un nom',
        },
        url: {
          empty: 'Veuillez renseigner un lien',
          patternMismatch: 'Veuillez vérifier l\'URL saisie',
        },
        video: {
          empty: 'Veuillez renseigner un vidéo',
          patternMismatch: 'Veuillez vérifier le code d\'intégration que vous avez saisi.',
        },
        file: {
          empty: 'Veuillez sélectionner un fichier',
          noFiles: 'Veuillez charger un fichier',
        },
      },
    },
  },
  de: {
    standPreview: 'Standvorschau',
    standManagerDetails: 'Fügen Sie Ihr Logo und Ihren Slogan zur Abbildung eines Standes hinzu, um Ihren Teilnehmern einen Vorgeschmack auf das persönliche Erlebnis zu geben.',
    sponsorLogo: 'Standlogo',
    sponsorLogoImageSuggestion: 'Die empfohlene Bildgröße ist 300 x 300 Pixel',
    slogan: 'Slogan',
    wordsRemaining: 'Verbleibende Wörter',
    mainBrandColor: 'Farbe der Hauptmarke',
    brandColorHexCodePlaceholder: 'Fügen Sie den farbigen Hex-Code ein',
    standImage: 'Standbild',
    standImageInstructions: 'Wählen Sie eines der folgenden Bilder aus. Das wird der Stand sein, der auf Ihrer Sponsorenseite zu sehen ist.',
    standSloganPlaceholder: 'Ihr Slogan hier!',
    enableStand: 'Stand aktivieren',
    updateSuccess: {
      title: 'Bleiben Sie auf dem Laufenden!',
      message: 'Ihr Stand wurde erfolgreich aktualisiert!',
    },
    updateError: {
      title: 'Stand-Update-Fehler!',
      message: 'Bei der Aktualisierung Ihres Standes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    },
    hotspots: {
      title: 'Hotspots',
      description: 'Klicken Sie auf einen der Hotspot-Typen unten und ziehen Sie ihn per Drag-and-Drop an die gewünschte Stelle auf dem Messestand. Sie können es verschieben und ändern, indem Sie darauf klicken.',
      hotspotName: 'Name des Hotspots',
      hotspotNamePlaceholder: 'Unsere Homepage',
      newHotspot: 'Neuer Hotspot',
      deleteHotspot: 'Hotspot löschen',
      updateHotspot: 'Hotspot aktualisieren',
      hotspotNotReady: 'Hotspot ist nicht bereit. Zum Aktualisieren hier klicken',
      types: {
        url: {
          hotspot: 'Hotspot mit Link',
          name: 'Verknüpfung',
          placeholder: 'https://www.yourpage.com',
        },
        video: {
          hotspot: 'Hotspot mit Video',
          name: 'Video',
          placeholder: 'Code hier einbetten',
        },
        file: {
          hotspot: 'Hotspot mit Datei',
          name: 'Datei',
          helper: 'Um eine Datei auszuwählen, laden Sie bitte zuerst eine in das Dateimodul hoch.',
        },
      },
      errors: {
        name: {
          empty: 'Bitte geben Sie einen Namen ein',
        },
        url: {
          empty: 'Bitte geben Sie einen Link ein',
          patternMismatch: 'Bitte überprüfen Sie die URL, die Sie eingegeben haben',
        },
        video: {
          empty: 'Bitte geben Sie einen gültigen Einbettungscode ein',
          patternMismatch: 'Bitte überprüfen Sie den Einbettungscode, den Sie eingegeben haben',
        },
        file: {
          empty: 'Bitte wählen Sie eine Datei aus.',
          noFiles: 'Bitte laden Sie eine Datei hoch',
        },
      },
    },
  },
};
