export default {
  en: {
    title: 'Attendee',
    title_plural: 'Attendees',
    categories: {
      all: 'All',
      registered: 'Registered',
      checkedIn: 'Checked in',
      deleted: 'Deleted',
    },
    actions: {
      createAttendee: 'Create attendee',
      createList: 'Create list',
      printBadge: 'Print badge',
      checkIn: 'Check in',
      addTag: 'Add tag',
      generateAppAccessToken: 'Generate app access token',
      deleteAttendee: 'Delete attendee',
      delete: 'Delete',
      exportAll: 'Export all to excel',
      printAll: 'Print all badges',
      save: 'Save',
      cancel: 'Cancel',
      insertCoupon: 'Insert coupon',
      addTransaction: 'Add transaction',
    },
    sections: {
      generalInfo: 'General info',
      communications: 'Communications',
      activities: 'Activities',
      payments: 'Payments',
      subtitles: {
        attendeePayments: 'Attendee payments',
      },
    },
    fields: {
      avatar: '',
      name: 'Name',
      status: 'Status',
      tags: 'Tags',
      attendeeType: 'Ticket type',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      company: 'Company',
      telephone: 'Telephone',
    },
    status: {
      paid: 'Paid',
      registered: 'Registered',
      confirmed: 'Confirmed',
      checkedIn: 'Checked in',
      deleted: 'Deleted',
    },
    payments: {
      status: {
        approved: 'Approved',
        rejected: 'Rejected',
        expired: 'Expired',
      },
      type: {
        deposit: 'Deposit',
      },
      summary: {
        subtotal: 'Subtotal',
        discount: 'Discount',
        vat: 'VAT',
        total: 'Total',
        balanceInFavor: 'Balance in favor',
      },
    },
    messages: {
      changeStatus: '???',
      emptyState: '{{user}}, we\'re waiting for the first attendee to register, but you can also create one here',
      createdByAt: 'Created by {{createdBy}} at {{createdAt}}',
    },
  },
  es: {
    title: 'Asistente',
    title_plural: 'Asistentes',
    categories: {
      all: 'Todos',
      registered: 'Registrados',
      checkedIn: 'Asistieron',
      deleted: 'Eliminados',
    },
    actions: {
      createAttendee: 'Crear asistente',
      createList: 'Crear lista',
      printBadge: 'Imprima la placa',
      checkIn: 'Registrarse',
      addTag: 'Añadir etiqueta',
      generateAppAccessToken: 'Generar el token de acceso a la aplicación',
      deleteAttendee: 'Eliminar asistente',
      delete: 'Borrar',
      exportAll: 'Exportar todo a Excel',
      printAll: 'Imprimir todas las insignias',
      save: 'Ahorrar',
      cancel: 'Cancelar',
      insertCoupon: 'Inserte el cupón',
      addTransaction: 'Añadir transacción',
    },
    sections: {
      generalInfo: 'Información general',
      communications: 'Comunicaciones',
      activities: 'Actividades',
      payments: 'Pagos',
      subtitles: {
        attendeePayments: 'Pagos de los asistentes',
      },
    },
    fields: {
      avatar: '',
      name: 'Nombre',
      status: 'Estado',
      tags: 'Etiquetas',
      attendeeType: 'Tipo de billete',
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo electrónico',
      company: 'Empresa',
      telephone: 'Teléfono',
    },
    status: {
      paid: 'Pagado',
      registered: 'Registrado',
      confirmed: 'Confirmado',
      checkedIn: 'Se registró',
      deleted: 'Eliminado',
    },
    payments: {
      status: {
        approved: 'Aprobado',
        rejected: 'Rechazado',
        expired: 'Caducado',
      },
      type: {
        deposit: 'Depósito',
      },
      summary: {
        subtotal: 'Subtotal',
        discount: 'Descuento',
        vat: 'CUBA',
        total: 'Total',
        balanceInFavor: 'Equilibrio a favor',
      },
    },
    messages: {
      changeStatus: '???',
      emptyState: '{{user}}, estamos esperando a que el primer asistente se registre, pero también puede crear uno aquí',
      createdByAt: 'Creado por {{createdBy}} en {{createdAt}}',
    },
  },
  fr: {
    title: 'Participant',
    title_plural: 'Participants',
    categories: {
      all: 'Toutes',
      registered: 'Inscrite',
      checkedIn: 'Enregistrée',
      deleted: 'Supprimé',
    },
    actions: {
      createAttendee: 'Créer un participant',
      createList: 'Créer une liste',
      printBadge: 'Badge imprimé',
      checkIn: 'Enregistrement',
      addTag: 'Ajouter un tag',
      generateAppAccessToken: "Générer un jeton d'accès aux applications",
      deleteAttendee: 'Supprimer un participant',
      delete: 'Supprimer',
      exportAll: 'Tout exporter vers Excel',
      printAll: 'Imprimez tous les badges',
      save: 'Économisez',
      cancel: 'Annuler',
      insertCoupon: 'Insérer un coupon',
      addTransaction: 'Ajouter une transaction',
    },
    sections: {
      generalInfo: 'Informations générales',
      communications: 'Communication',
      activities: 'Activités',
      payments: 'Paiements',
      subtitles: {
        attendeePayments: 'Paiements aux participants',
      },
    },
    fields: {
      avatar: '',
      name: 'Nom',
      status: 'Statut',
      tags: 'Tags',
      attendeeType: 'Type de billet',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      email: 'E-mail',
      company: 'Entreprise',
      telephone: 'Téléphone',
    },
    status: {
      paid: 'Payée',
      registered: 'Inscrite',
      confirmed: 'Confirmé',
      checkedIn: 'Enregistrée',
      deleted: 'Supprimé',
    },
    payments: {
      status: {
        approved: 'Approuvé',
        rejected: 'Rejeté',
        expired: 'Expiré',
      },
      type: {
        deposit: 'Dépôt',
      },
      summary: {
        subtotal: 'Sous-total',
        discount: 'Réduction',
        vat: 'TVA',
        total: 'Total',
        balanceInFavor: 'Équilibre en faveur',
      },
    },
    messages: {
      changeStatus: '? ? ?',
      emptyState: "{{user}}, nous attendons que le premier participant s'inscrive, mais vous pouvez également en créer un ici",
      createdByAt: 'Créé par {{createdBy}} à {{createdAt}}',
    },
  },
  de: {
    title: 'Teilnehmer',
    title_plural: 'Teilnehmer',
    categories: {
      all: 'Alles',
      registered: 'Registriert',
      checkedIn: 'Eingecheckt',
      deleted: 'Gelöscht',
    },
    actions: {
      createAttendee: 'Teilnehmer erstellen',
      createList: 'Liste erstellen',
      printBadge: 'Plakette drucken',
      checkIn: 'Einchecken',
      addTag: 'Tag hinzufügen',
      generateAppAccessToken: 'App-Zugriffstoken generieren',
      deleteAttendee: 'Teilnehmer löschen',
      delete: 'Löschen',
      exportAll: 'Alles nach Excel exportieren',
      printAll: 'Drucken Sie alle Abzeichen',
      save: 'Speichern',
      cancel: 'Stornieren',
      insertCoupon: 'Gutschein einlegen',
      addTransaction: 'Transaktion hinzufügen',
    },
    sections: {
      generalInfo: 'Allgemeine Informationen',
      communications: 'Kommunikation',
      activities: 'Aktivitäten',
      payments: 'Zahlungen',
      subtitles: {
        attendeePayments: 'Zahlungen für Teilnehmer',
      },
    },
    fields: {
      avatar: '',
      name: 'Name',
      status: 'Status',
      tags: 'Schlagworte',
      attendeeType: 'Art des Tickets',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      company: 'Firma',
      telephone: 'Telefon',
    },
    status: {
      paid: 'Bezahlt',
      registered: 'Registriert',
      confirmed: 'Bestätigt',
      checkedIn: 'Eingecheckt',
      deleted: 'Gelöscht',
    },
    payments: {
      status: {
        approved: 'Genehmigt',
        rejected: 'Abgelehnt',
        expired: 'Abgelaufen',
      },
      type: {
        deposit: 'Einzahlung',
      },
      summary: {
        subtotal: 'Zwischensumme',
        discount: 'Rabatt',
        vat: 'MEHRWERTSTEUER',
        total: 'Insgesamt',
        balanceInFavor: 'Ausgewogenheit dafür',
      },
    },
    messages: {
      changeStatus: '???',
      emptyState: '{{user}}, wir warten darauf, dass sich der erste Teilnehmer registriert, aber Sie können hier auch einen erstellen',
      createdByAt: 'Erstellt von {{createdBy}} bei {{createdAt}}',
    },
  },
};
