export default {
  en: {
    addProduct: 'Add product',
    addProductDescription: 'Add and edit products you wish to showcase and sell.',
    productName: 'Product name',
    price: 'Price',
    pricePlaceholder: 'Price will be shown in {{currency}}',
    productImageSuggestedSize: 'The suggested image size is 500 x 500px',
    description: 'Description',
    descriptionPlaceholder: 'As a sponsor you have 800 characters available to describe your product',
    linkToPurchase: 'Link to purchase product',
    saveChanges: 'Save changes',
    errorUrl: 'Please check the URL you’ve entered',
    edit: 'Edit',
    delete: 'Delete',
    formErrors: {
      fieldRequired: 'Required field',
    },
    alerts: {
      errorImage: 'Error',
      errorImageDescription: 'Add a image to save changes',
      productCreated: 'Product created',
      productCreatedDescription: 'Sponsor product was successfully created',
      productCreatedError: 'Product creation error!',
      productCreatedErrorDescription: 'There was an issue creating the sponsor product',
      productUpdated: 'Product updated',
      productUpdatedDescription: 'Sponsor product was successfully updated',
      productUpdateError: 'Product update error!',
      productUpdateErrorDescription: 'There was an issue updating the sponsor product',
      productDeleted: 'Product deleted',
      productDeletedDescription: 'Sponsor product was successfully deleted',
      productDeletedError: 'Product deletion error!',
      productDeletedErrorDescription: 'There was an issue deleting the sponsor product',
    },
  },
  es: {
    addProduct: 'Agregar productos',
    addProductDescription: 'Agrega y edita los productos que deseas mostrar y vender.',
    productName: 'Nombre del producto',
    price: 'Precio',
    pricePlaceholder: 'El precio se mostrará en {{currency}}',
    productImageSuggestedSize: 'El tamaño sugerido para la imagen es de 500 x 500px',
    description: 'Descripción',
    descriptionPlaceholder: 'Como sponsor, tienes 800 caracteres disponibles para describir tu producto',
    linkToPurchase: 'Link para adquirir el producto',
    saveChanges: 'Guardar cambios',
    errorUrl: 'Por favor comprueba la URL que has introducido',
    edit: 'Editar',
    delete: 'Eliminar',
    formErrors: {
      fieldRequired: 'Campo requerido',
    },
    alerts: {
      errorImage: 'Error',
      errorImageDescription: 'Agrega una imagen para guardar cambios',
      productCreated: 'Producto creado',
      productCreatedDescription: 'El producto del patrocinador se ha creado con éxito',
      productCreatedError: 'Error al crear el producto!',
      productCreatedErrorDescription: 'Ocurrió un error creando el producto del patrocinador',
      productUpdated: 'Producto actualizado',
      productUpdatedDescription: 'El producto del patrocinador se ha actualizado con éxito',
      productUpdateError: 'Error al actualizar el producto!',
      productUpdateErrorDescription: 'Ocurrió un error actualizando el producto del patrocinador',
      productDeleted: 'Producto eliminado',
      productDeletedDescription: 'El producto del patrocinador se ha eliminado con éxito',
      productDeletedError: 'Error al eliminar el producto!',
      productDeletedErrorDescription: 'Ocurrió un error eliminando el producto del patrocinador',
    },
  },
  fr: {
    addProduct: 'Ajouter un produit',
    addProductDescription: 'Ajoutez et éditez les produits que vous souhaitez présenter et vendre. ',
    productName: 'Nom du produit',
    price: 'Prix',
    pricePlaceholder: 'Prix afichés en {{currency}}',
    productImageSuggestedSize: 'La taille recommandée pour l\'image est de 500 x 500 px',
    description: 'Description',
    descriptionPlaceholder: 'En tant que sponsor, vous disposez de 800 caractères pour décrire votre produit',
    linkToPurchase: 'Lien vers l\'achat du produit ',
    saveChanges: 'Sauvegarder les changements',
    errorUrl: 'Veuillez vérifier l\'URL que vous avez saisie',
    edit: 'Éditer',
    delete: 'Supprimer',
    formErrors: {
      fieldRequired: 'Champ obligatoire',
    },
    alerts: {
      errorImage: 'Erreur',
      errorImageDescription: 'Ajouter une image pour sauvegarder',
      productCreated: 'Produit créé',
      productCreatedDescription: 'Produit sponsor créé avec succès',
      productCreatedError: 'Erreur de création de produit!',
      productCreatedErrorDescription: 'Un problème est survenu lors de la création du produit sponsor',
      productUpdated: 'Produit mis à jour',
      productUpdatedDescription: 'Produit Sponsor mis à jour avec succès',
      productUpdateError: 'Erreur de mise à jour des informations!',
      productUpdateErrorDescription: 'Une de la mise à sur le sponsor.',
      productDeleted: 'Produit supprimé',
      productDeletedDescription: 'Le produit du sponsor a été supprimé avec succès',
      productDeletedError: 'Erreur de suppression de produit!',
      productDeletedErrorDescription: 'Un problème est survenu lors de la suppression du produit du sponsor',
    },
  },
  de: {
    addProduct: 'Produkt hinzufügen',
    addProductDescription: 'Fügen Sie Produkte hinzu und bearbeiten Sie sie, die Sie präsentieren und verkaufen möchten.',
    productName: 'Name des Produkts',
    price: 'Preis',
    pricePlaceholder: 'Der Preis wird in {{currency}} angezeigt',
    productImageSuggestedSize: 'Die empfohlene Bildgröße ist 500 x 500 Pixel',
    description: 'Beschreibung',
    descriptionPlaceholder: 'Als Sponsor stehen Ihnen 800 Zeichen zur Verfügung, um Ihr Produkt zu beschreiben',
    linkToPurchase: 'Link zum Kauf eines Produkts',
    saveChanges: 'Änderungen speichern',
    errorUrl: 'Bitte überprüfen Sie die URL, die Sie eingegeben haben',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    formErrors: {
      fieldRequired: 'Erforderliches Feld',
    },
    alerts: {
      errorImage: 'Fehler',
      errorImageDescription: 'Fügen Sie ein Bild hinzu, um die Änderungen zu speichern',
      productCreated: 'Produkt wurde erstellt',
      productCreatedDescription: 'Das Sponsorprodukt wurde erfolgreich erstellt',
      productCreatedError: 'Fehler bei der Produkterstellung!',
      productCreatedErrorDescription: 'Es gab ein Problem beim Erstellen des Sponsorprodukts',
      productUpdated: 'Produkt wurde aktualisiert',
      productUpdatedDescription: 'Das Produkt des Sponsors wurde erfolgreich aktualisiert',
      productUpdateError: 'Fehler beim Produktupdate!',
      productUpdateErrorDescription: 'Es gab ein Problem beim Aktualisieren des Sponsorprodukts',
      productDeleted: 'Produkt wurde gelöscht',
      productDeletedDescription: 'Das Sponsorprodukt wurde erfolgreich gelöscht',
      productDeletedError: 'Fehler beim Löschen des Produkts!',
      productDeletedErrorDescription: 'Es gab ein Problem beim Löschen des Sponsorprodukts',
    },
  },
};
