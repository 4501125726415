import { getTokenContent } from './auth';

export const getEventUri = () => window.location.pathname.split('/')[2];
export const getEntity = (entities, key) => (entities[key] && Object.values(entities[key])[0]);
export const getEntityById = (entities, key, id) => (entities[key] && entities[key][id]);

export const getRepresentativeById = (entities, representativeId) => (
  getEntityById(entities, 'attendees', String(representativeId))
);

export const getCurrentAttendee = (entities) => {
  const eventUri = getEventUri();
  const attendeeId = getTokenContent(eventUri)?.attendee_id;

  const attendee = getEntityById(entities, 'attendees', String(attendeeId));

  // if (options?.parseCustomFields && attendee?.fields) attendee = {
  //   ...attendee,
  //   ...parseCustomFields(attendee?.fields, entities.attendeeTypeCustomFields),
  // };

  return attendee;
};

export const getSponsorId = () => {
  const eventUri = getEventUri();
  const sponsorId = getTokenContent(eventUri)?.sponsor_id;
  return sponsorId;
};

export const getSidebarItemsProps = (entities) => {
  const { sponsors } = entities;
  const sponsorId = getSponsorId();
  const sponsor = (sponsors || {})[sponsorId] || {};
  const { workshopCreationEnabled, category } = sponsor || {};

  return {
    disableActivities: !workshopCreationEnabled,
    disableRepresentatives: !category?.id,
  };
};

export const getCurrentParticipant = (entities, businessConferenceId = null) => {
  const { businessConferenceParticipants } = entities;
  const currentAttendee = getCurrentAttendee(entities);

  if (!currentAttendee) return undefined;

  const { businessConferenceParticipants: associatedParticipants } = currentAttendee;
  const participantId = associatedParticipants.map(({ id }) => id);

  return participantId && businessConferenceParticipants && (
    businessConferenceId ? (
      Object.values(businessConferenceParticipants).find((participant) => {
        const conferenceId = participant?.businessConference?.id;
        return (
          participantId.includes(participant.id) && conferenceId === businessConferenceId
        );
      })
    ) : (
      getEntityById(entities, 'businessConferenceParticipants', participantId[0])
    )
  );
};
