import PropTypes from 'prop-types';

const formErrors = PropTypes.objectOf(PropTypes.shape({
  type: PropTypes.string,
  message: PropTypes.string,
  ref: PropTypes.shape({
    type: PropTypes.string,
  }),
}));

const reaction = PropTypes.shape({
  emoji: PropTypes.string,
  name: PropTypes.string,
  uuid: PropTypes.string,
});

const message = PropTypes.shape({
  uuid: PropTypes.string,
  body: PropTypes.string,
  attendee_id: PropTypes.string,
  username: PropTypes.string,
  avatar: PropTypes.string,
  timestamp: PropTypes.string,
});

const question = PropTypes.shape({
  uuid: PropTypes.string,
  body: PropTypes.string,
  timestamp: PropTypes.string,
});

const event = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  eventUri: PropTypes.string,
  whiteLabel: PropTypes.bool,
});

const appSettings = PropTypes.shape({
  id: PropTypes.string,
  darkTheme: PropTypes.bool,
  waitingImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  languageSelectorEnabled: PropTypes.bool,
  backgroundImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  icon: PropTypes.shape({
    filename: PropTypes.string,
    thumb: PropTypes.string,
  }),
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
    small: PropTypes.string,
  }),
  modules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string,
    enabled: PropTypes.bool,
    customParams: PropTypes.shape({
      content: PropTypes.string,
      icon: PropTypes.string,
    }),
  })),
});

const meeting = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  host: PropTypes.shape({
    id: PropTypes.string,
  }),
  participant: PropTypes.shape({
    id: PropTypes.string,
  }),
  businessConferenceId: PropTypes.number,
  status: PropTypes.number,
  userMessage: PropTypes.string,
});

const person = PropTypes.shape({
  id: PropTypes.string,
  participantId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  country: PropTypes.string,
  telephone: PropTypes.string,
  type: PropTypes.oneOf(['attendee', 'participant']),
  logo: PropTypes.shape({
    filename: PropTypes.string,
    small: PropTypes.string,
  }),
});

const attendee = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  company: PropTypes.string,
  jobTitle: PropTypes.string,
  telephone: PropTypes.string,
  email: PropTypes.string,
  headshot: PropTypes.shape({
    filename: PropTypes.string,
    thumb: PropTypes.string,
    small: PropTypes.string,
    medium: PropTypes.string,
    large: PropTypes.string,
    original: PropTypes.string,
  }),
  message: PropTypes.string,
});

const participant = PropTypes.shape({
  id: PropTypes.string,
  profileContactName: PropTypes.string,
  profileDisplayName: PropTypes.string,
  blockedSlots: PropTypes.arrayOf(PropTypes.string),
});

const participantType = PropTypes.shape({
  id: PropTypes.string,
  canRequestMeetingToAttendeeTypes: PropTypes.arrayOf(PropTypes.number),
  agenda: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
});

const survey = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
});

const attendeeTypeCustomField = PropTypes.shape({
  id: PropTypes.string,
});

const attendeeTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const customField = PropTypes.shape({
  id: PropTypes.string,
});

const workshop = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  streamingType: PropTypes.string,
  streamingUrl: PropTypes.string,
  streamingEmbedCode: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      embedCode: PropTypes.string,
    })
  ),
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  enabledEmotions: PropTypes.bool,
});

const attendeeWorkshops = PropTypes.shape({
  attendee: PropTypes.shape({
    id: PropTypes.string,
  }),
  workshop: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const workshopCategory = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const eventFile = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  eventFile: PropTypes.string,
  entityType: PropTypes.string,
  entityId: PropTypes.number,
});

const speaker = PropTypes.shape({
  fullName: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  picture: PropTypes.shape({
    filename: PropTypes.string,
    small: PropTypes.string,
  }),
});

const note = PropTypes.shape({
  body: PropTypes.string,
  reviewed: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
});

const channel = PropTypes.shape({
  topic: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  type: PropTypes.oneOf(['private', 'group', 'announcement']),
});

const filter = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  PropTypes.shape({}),
]);

const entities = PropTypes.objectOf(
  PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  )
);

const conference = PropTypes.shape({
  id: PropTypes.string,
  meetingRejectionEnabled: PropTypes.bool,
  meetingRejectionDescriptionOptions: PropTypes.arrayOf(PropTypes.string),
});

const meta = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
      ])
    ),
  ])
);

const sponsor = PropTypes.shape({
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  bannerImage: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  id: PropTypes.string,
  fileCategoryId: PropTypes.number,
  description: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  category: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const sponsorRepresentative = PropTypes.shape({
  id: PropTypes.string,
  active: PropTypes.bool,
  attendeeId: PropTypes.number,
});

const sponsorCategory = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  sponsorRepresentativeSettings: PropTypes.objectOf(PropTypes.string),
});

const hotspot = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  contentType: PropTypes.oneOf(['url', 'video', 'info', 'file']),
  coordinates: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
});

const links = PropTypes.shape({
  self: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  first: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prev: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  next: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  last: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

const tags = PropTypes.shape({
  atributes: PropTypes.shape({
    name: PropTypes.string,
    parent_tag_id: PropTypes.number,
  }),
});

const product = PropTypes.shape({
  description: PropTypes.string,
  image: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  name: PropTypes.string,
  price: PropTypes.number,
  purchaseLink: PropTypes.string,
});

export default {
  formErrors,
  reaction,
  message,
  question,
  conference,
  event,
  appSettings,
  meeting,
  person,
  filter,
  attendee,
  attendeeTypeCustomField,
  attendeeTypes,
  customField,
  participant,
  participantType,
  survey,
  workshop,
  attendeeWorkshops,
  workshopCategory,
  eventFile,
  speaker,
  note,
  channel,
  entities,
  meta,
  sponsor,
  sponsorCategory,
  sponsorRepresentative,
  hotspot,
  links,
  tags,
  product,
};
