const TOKEN_KEY = 'sponsors-tokens';
const store = localStorage;

export const setToken = (eventUri, token) => {
  const rawAuthTokens = store.getItem(TOKEN_KEY);
  const authTokens = rawAuthTokens ? JSON.parse(rawAuthTokens) : {};
  authTokens[eventUri] = token;
  store.setItem(TOKEN_KEY, JSON.stringify(authTokens));
};

export const removeToken = (eventUri) => {
  const authTokens = JSON.parse(store.getItem(TOKEN_KEY));
  if (!authTokens) return;
  delete authTokens[eventUri];
  store.setItem(TOKEN_KEY, JSON.stringify(authTokens));
};

export const getToken = (eventUri) => {
  const rawAuthTokens = store.getItem(TOKEN_KEY);
  if (!rawAuthTokens) return undefined;
  return JSON.parse(rawAuthTokens)[eventUri];
};

export const loggedIn = (eventUri) => !!getToken(eventUri);

export const getTokenContent = (eventUri) => {
  const token = getToken(eventUri);
  if (!token) return undefined;
  const [, payload] = token.split('.');
  return JSON.parse(atob(payload));
};

export const identifyParticipants = (eventUri, meeting, participants) => {
  if (!eventUri || !meeting || !participants) return {};
  const participantId = getTokenContent(eventUri).participant_id;

  const currentParticipantId = String(participantId[0]);
  const otherParticipantId = meeting.host.id === currentParticipantId
    ? meeting.participant.id : meeting.host.id;

  return {
    currentParticipant: participants[currentParticipantId],
    otherParticipant: participants[otherParticipantId],
  };
};
