export default {
  en: {
    description: 'Add files and information for attendees to download, for example: catalogues, brochures, product specs anything else you might need. You can upload up to 10 files.',
    uploadAttachment: 'Upload attachment',
    attachmentSuggested: 'This file can be a png, jpg, pdf or zip and a maximum of 10MB',
    attachmentName: 'Attachment name',
    attachmentDescription: 'Attachment description',
    namePlaceholder: 'File name',
    descriptionPlaceholder: 'Describe what information attendees will see after downloading this attachment.',
    addAttachment: 'Add another attachment',
    saveChanges: 'Save changes',
    deleteFile: 'Delete file?',
    deleteText: 'Are you sure you want to delete this file?',
    fileUploaded: 'File uploaded!',
    fileUploadedDescription: 'Your file was uploaded succesfully',
    fileUploadError: 'upload error',
    fileUploadErrorDescription: 'There was an error uploading this file',
    fileSizeError: 'Size error',
    fileSizeErrorDescription: 'Your file is exceeding the maximum file size (10 MB)',
    maxFileError: 'Maximum files error',
    maxFileErrorDescription: 'You already reach the maximum number of files that you are able to upload (10 files)',
    delete: 'Delete File',
    download: 'Download File',
    fileDeleted: 'Deleted file!',
    fileDeletedDescription: 'The file was succesfully deleted',
    fileUpdated: 'Updated file!',
    fileUpdatedDescription: 'Your files were succesfully updated',
    fileUpdateError: 'Update error',
    fileUpdateErrorDescription: 'There was an error updating this file',
    fileUpdateErrorType: 'This file type is not supported, try uploading a different one.',
  },
  es: {
    description: 'Añada archivos e información para que los asistentes puedan descargarlos, por ejemplo: catálogos, folletos, especificaciones de productos y cualquier otra cosa que pueda necesitar. puedes subir hasta 10 archivos.',
    uploadAttachment: 'Cargar archivo adjunto',
    attachmentSuggested: 'Este archivo puede ser un png, jpg, pdf o zip y un máximo de 10MB',
    attachmentName: 'Nombre de archivo',
    attachmentDescription: 'Descripción del archivo adjunto',
    namePlaceholder: 'Nombre de archivo',
    descriptionPlaceholder: 'Describa qué información verán los asistentes tras descargar este archivo adjunto.',
    addAttachment: 'Agregar otro archivo adjunto',
    saveChanges: 'Guardar cambios',
    deleteFile: 'Borrar archivo?',
    deleteText: 'Estas seguro que deseas borrar este archivo?',
    fileUploaded: 'Archivo creado!',
    fileUploadedDescription: 'Tu archivo se ha creado correctamente',
    fileUploadError: 'Error al crear archivo',
    fileUploadErrorDescription: 'Ocurrió un error creando este archivo',
    fileSizeError: 'Error en el tamaño del archivo',
    fileSizeErrorDescription: 'Tu archivo está excediendo el tamaño máximo (10 MB)',
    maxFileError: 'Limite de archivos',
    maxFileErrorDescription: 'Has alcanzado el número máximo de arhivos permitidos (10 files)\n',
    delete: 'Archivo eliminado',
    download: 'Descargar archivo',
    fileDeleted: 'Archivo eliminado!',
    fileDeletedDescription: 'El archivo ha sido eliminado ',
    fileUpdated: 'Archivo actualizado',
    fileUpdatedDescription: 'Tus archivos fueron actualizados correctamente',
    fileUpdateError: 'Error al actualizar',
    fileUpdateErrorDescription: 'Ocurrió un error actualizando tus archivos',
    fileUpdateErrorType: 'Este tipo de archivo no está soportado, intenta cargar un archivo diferente.',
  },
  fr: {
    description: 'Ajoutez des fichiers et des informations téléchargeables pour les participants. Exemple : catalogues, brochures, fiche technique produit... ce dont ils pourraient avoir besoin.',
    uploadAttachment: 'Ajouter fichier',
    attachmentSuggested: 'Le fichier peut être en format png, jpg, pdf ou zip. Taille maximale 10MB',
    attachmentName: 'Description du fichier',
    attachmentDescription: 'Décrivez l\'information qui sera visible aux participants une fois qu\'ils auront téléchargé le fichier',
    namePlaceholder: 'Nom du fichier',
    descriptionPlaceholder: 'Sauvegarder les modifications',
    addAttachment: 'Ajouter une autre pièce jointe',
    saveChanges: 'Sauvegarder les changements',
    deleteFile: 'Supprimer le fichier ?',
    deleteText: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
    fileUploaded: 'Fichier téléchargé !',
    fileUploadedDescription: 'Votre fichier a été téléchargé avec succès',
    fileUploadError: 'Erreur de téléchargement',
    fileUploadErrorDescription: 'Une erreur s\'est produite lors du téléchargement de ce fichier',
    fileSizeError: 'Erreur de taille',
    fileSizeErrorDescription: 'Votre fichier dépasse la taille maximale du fichier (10 MB)',
    maxFileError: 'Limite de fichiers atteinte',
    maxFileErrorDescription: 'Vous avez déjà atteint le nombre maximum de fichiers que vous pouvez télécharger (10 fichiers).',
    delete: 'Supprimer le fichier',
    download: 'Fichier de téléchargement',
    fileDeleted: 'Fichier supprimé !',
    fileDeletedDescription: 'Le fichier a été supprimé avec succès',
    fileUpdated: 'Fichier mis à jour !',
    fileUpdatedDescription: 'Vos fichiers ont été mis à jour avec succès',
    fileUpdateError: 'Erreur de mise à jour',
    fileUpdateErrorDescription: 'Une erreur s\'est produite lors de la mise à jour de ce fichier',
    fileUpdateErrorType: 'Ce type de fichier n\'est pas pris en charge, essayez d\'en télécharger un autre.',
  },
  de: {
    description: 'Fügen Sie Dateien und Informationen für die Teilnehmer zum Herunterladen hinzu, zum Beispiel: Kataloge, Broschüren, Produktspezifikationen, alles andere, was Sie benötigen könnten. Sie können bis zu 10 Dateien hochladen.',
    uploadAttachment: 'Anlage hochladen',
    attachmentSuggested: 'Diese Datei kann eine PNG-, JPG-, PDF- oder Zip-Datei sein und maximal 10 MB groß sein.',
    attachmentName: 'Name des Anhangs',
    attachmentDescription: 'Beschreibung des Anhangs',
    namePlaceholder: 'Name der Datei',
    descriptionPlaceholder: 'Beschreiben Sie, welche Informationen die Teilnehmer nach dem Herunterladen dieses Anhangs sehen werden.',
    addAttachment: 'Einen weiteren Anhang hinzufügen',
    saveChanges: 'Änderungen speichern',
    deleteFile: 'Datei löschen?',
    deleteText: 'Sind Sie sicher, dass Sie diese Datei löschen möchten?',
    fileUploaded: 'Datei hochgeladen!',
    fileUploadedDescription: 'Ihre Datei wurde erfolgreich hochgeladen',
    fileUploadError: 'Fehler beim Hochladen',
    fileUploadErrorDescription: 'Beim Hochladen dieser Datei ist ein Fehler aufgetreten',
    fileSizeError: 'Größenfehler',
    fileSizeErrorDescription: 'Ihre Datei überschreitet die maximale Dateigröße (10 MB)',
    maxFileError: 'Fehler bei der maximalen Anzahl von Dateien',
    maxFileErrorDescription: 'Sie haben bereits die maximale Anzahl an Dateien erreicht, die Sie hochladen können (10 Dateien)',
    delete: 'Datei löschen',
    download: 'Datei herunterladen',
    fileDeleted: 'Datei gelöscht!',
    fileDeletedDescription: 'Die Datei wurde erfolgreich gelöscht',
    fileUpdated: 'Datei wurde aktualisiert!',
    fileUpdatedDescription: 'Ihre Dateien wurden erfolgreich aktualisiert',
    fileUpdateError: 'Fehler beim Update',
    fileUpdateErrorDescription: 'Beim Aktualisieren dieser Datei ist ein Fehler aufgetreten.',
    fileUpdateErrorType: 'Dieser Dateityp wird nicht unterstützt, versuchen Sie, einen anderen hochzuladen.',
  },
};
