import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import EventtiaLogo from 'eventtia-ui-components/lib/EventtiaLogo';
import Card from 'eventtia-ui-components/lib/Card';
import CustomPropTypes from '../../helpers/CustomPropTypes';

import loginBackground from '../../images/loginBackground.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    right: 0,
    left: 0,
  },
  main: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
    backgroundImage: `url('${loginBackground}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    width: 542,
    maxWidth: '100%',
  },
  titleContainer: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    color: theme.palette.common.white,
    alignItems: 'center',
    '& > h1': {
      fontWeight: 'bold',
    },
  },
  mobileTitleContainer: {
    flexDirection: 'column',
  },
  logo: {
    height: '3em',
    maxWidth: '33%',
  },
  pipe: {
    margin: theme.spacing(0, 2),
    fontSize: theme.spacing(4),
    lineHeight: '1em',
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 2),
    },
  },
  sponsorTitle: {
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: theme.palette.lightGrey.dark,
  },
  sponsorSubtitle: {
    fontSize: '12px',
    color: theme.palette.lightGrey.dark,
  },
  poweredBy: {
    fontSize: theme.typography.body2.fontSize,
  },
  eventtia: {
    height: 13,
    width: 'auto',
    marginLeft: theme.spacing(1),
  },
  contentRoot: {
    backgroundColor: theme.palette.lightGrey.main,
    maxWidth: '100%',
    borderRadius: theme.spacing(1),
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  content: {
    padding: theme.spacing(6),
    minWidth: '45%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  },
  mobileContent: {
    padding: theme.spacing(6, 0),
  },
  poweredByContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
    color: 'white',
  },
}));

const LoggedOutLayout = ({
  entities, children, eventName, eventLogo,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('global');

  const [event] = Object.values(entities.events || {});
  const { whiteLabel } = event || {};
  // const smallMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div
      className={classes.root}
    >
      <main className={classes.main}>
        <div className={classes.container}>
          <div
            className={clsx(classes.titleContainer,
              mobile && classes.mobileTitleContainer)}
          >
            {
              eventLogo
                ? <img alt={eventName} src={eventLogo} />
                : <EventtiaLogo className={classes.logo} />
            }
            {!mobile && (
              <span className={classes.pipe}>|</span>
            )}
            <div>
              <Typography className={classes.sponsorTitle}>{t('sponsorProfileEditor')}</Typography>
              <Typography className={classes.sponsorSubtitle}>{eventName}</Typography>
            </div>
          </div>

          <Card className={classes.contentRoot}>
            <div className={clsx(classes.content, mobile && classes.mobileContent)}>
              {children}
            </div>
            <Divider flex="true" />
          </Card>
          {!whiteLabel && (
            <div className={classes.poweredByContainer}>
              <Typography variant="caption" className={classes.poweredBy}>
                {t('sponsors.poweredBy')}
              </Typography>
              <EventtiaLogo className={classes.eventtia} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

LoggedOutLayout.propTypes = {
  children: PropTypes.node.isRequired,
  eventName: PropTypes.string.isRequired,
  eventLogo: PropTypes.string.isRequired,
  entities: CustomPropTypes.entities.isRequired,
};

const mapStateToProps = ({
  entities,
}) => ({
  entities,
});

export default connect(mapStateToProps)(LoggedOutLayout);
