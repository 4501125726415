import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  languageButton: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: theme.palette.darkGrey.light,
    opacity: 0.6,
    marginLeft: theme.spacing(1),
  },
  darkLanguageButton: {
    color: theme.palette.common.white,
    opacity: 1,
  },
  languageIcon: {
    marginRight: theme.spacing(1),
  },
  arrowDownIcon: {
    color: theme.palette.darkGrey.light,
  },
  menu: {
    zIndex: 1,
  },
  item: {
    padding: theme.spacing(2, 2),
    color: theme.palette.darkGrey.light,
    opacity: 0.6,
  },
  currentLanguage: {
    color: theme.palette.darkGrey.light,
    opacity: 0.6,
  },
  checkIcon: {
    height: 16,
    marginLeft: theme.spacing(1),
  },
}));

const LanguageSwitcher = ({ darkTheme }) => {
  const classes = useStyles();
  const { locale } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation('global');
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const languages = ['en', 'es', 'fr', 'de'];
  const history = useHistory();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLocation = (newLocale) => {
    const newLocation = pathname.replace(locale, newLocale);
    history.push(newLocation);
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(classes.languageButton,
          darkTheme && classes.darkLanguageButton)}
      >
        <LanguageIcon className={classes.languageIcon} />
        {t(`languages.${locale}`)}
        <ArrowDropDownIcon className={classes.arrowDownIcon} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className={classes.menu}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {languages.map((language) => (
                    <MenuItem
                      key={language}
                      onClick={() => handleLocation(language)}
                      className={clsx(classes.item, locale === language && classes.currentLanguage)}
                    >
                      {t(`languages.${language}`)}
                      {locale === language && <CheckIcon className={classes.checkIcon} />}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

LanguageSwitcher.propTypes = {
  darkTheme: PropTypes.bool,
};

LanguageSwitcher.defaultProps = {
  darkTheme: false,
};

export default LanguageSwitcher;
