import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import filters from '../middlewares/filters';
import api from '../middlewares/api';
import auth from '../middlewares/auth';

const configureStore = (preloadedState) => createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunk, filters, api, auth)
);

export default configureStore;
