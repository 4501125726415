import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EventtiaLogo from 'eventtia-ui-components/lib/EventtiaLogo';
import IconButton from '@material-ui/core/IconButton';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

import Clock from '../Clock';
import LanguageSwitcher from '../LanguageSwitcher';
import { logout } from '../../actions/app';
import logOutIcon from '../../assets/logout.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '140px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 7, 3, 12),
  },
  info: {
    flex: 1,
  },
  eventLogo: {
    maxHeight: 60,
    maxWidth: 220,
  },
  defaultLogo: {
    maxHeight: 60,
    color: '#761D77',
  },
  title: {
    color: '#851486',
    marginBottom: theme.spacing(0.5),
    fontSize: 24,
    fontFamily: 'montserrat',
    fontWeight: 'bold',
  },
  subTitle: {
    color: theme.palette.darkGrey.main,
    fontSize: 14,
  },
  darkThemeTitle: {
    color: theme.palette.common.white,
  },
  eventtia: {
    height: 10,
    width: 'auto',
  },
  logoContainer: {
    width: 204,
    display: 'flex',
    justifyContent: 'center',
  },
  logOutButtonContainer: {
    justifyContent: 'left',
    paddingLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logOutIconContainer: {
    marginLeft: 3,
    display: 'flex',
    justifyContent: 'left',
  },
  logOutIcon: {
    width: 24,
    opacity: 0.3,
  },
  logOutLabel: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.darkGrey.light,
    fontSize: 14,
    opacity: 0.6,
  },
}));

const Header = ({
  eventName,
  eventLogo,
  logout: dispatchLogout,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  return (
    <div className={clsx(classes.root)}>
      <div className={classes.logoContainer}>
        {
          eventLogo
            ? <img alt={eventName} className={classes.eventLogo} src={eventLogo} />
            : <EventtiaLogo className={classes.defaultLogo} />
        }
      </div>
      <Divider variant="middle" orientation="vertical" flexItem />
      <div className={clsx(classes.info)}>
        <Typography
          variant="h2"
          color="textPrimary"
          className={clsx(classes.title)}
        >
          {t('sponsorProfileEditor')}
        </Typography>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={clsx(classes.subTitle)}
        >
          {eventName}
        </Typography>
      </div>
      <Clock />
      <LanguageSwitcher />
      <IconButton
        disableRipple
        className={classes.logOutButtonContainer}
        onClick={() => {
          dispatchLogout();
        }}
        disableFocusRipple
      >
        <div className={classes.logOutIconContainer}>
          <img src={logOutIcon} alt="logOutIcon" className={classes.logOutIcon} />
          <Typography className={classes.logOutLabel}>{t('actions.logout')}</Typography>
        </div>
      </IconButton>
    </div>
  );
};

Header.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventLogo: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(Header);
