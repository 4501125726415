import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Loader from 'eventtia-ui-components/lib/Loader';

import Header from '../Header';
import callApi from '../../actions/callApi';
import { getSponsorId } from '../../helpers/getters';

// import { logout } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    right: 0,
    left: 0,
    backgroundColor: '#FAFAFD',
  },
  main: {
    flex: 1,
    position: 'relative',
    height: 'calc(100vh - 140px)',
  },
  headerContainer: {
    display: 'flex',
    position: 'sticky',
    top: theme.spacing(0),
    zIndex: '1',
    backgroundColor: '#FAFAFD',
  },
}));

export const Component = ({
  children,
  eventName,
  eventLogo,
  callApi: dispatchCallApi,
  isFetchingSponsor,
}) => {
  const classes = useStyles();
  const { eventUri } = useParams();
  const sponsorId = getSponsorId();
  // const { t } = useTranslation('global');

  // const userMenuItems = [
  //   { name: t('actions.logout'), onClick: dispatchLogout },
  // ];

  useEffect(() => {
    if (sponsorId) dispatchCallApi('sponsor', { eventUri, sponsorId });
  }, [dispatchCallApi, eventUri, sponsorId]);

  return (
    !isFetchingSponsor && !!sponsorId ? (
      <div className={classes.root}>
        <div className={classes.headerContainer}>
          <Header eventName={eventName} eventLogo={eventLogo} />
        </div>
        <main className={classes.main}>
          {children}
        </main>
      </div>
    ) : (
      <Loader loading={isFetchingSponsor} variant="absolute" />
    )
  );
};

Component.propTypes = {
  children: PropTypes.node.isRequired,
  eventName: PropTypes.string.isRequired,
  eventLogo: PropTypes.string.isRequired,
  callApi: PropTypes.func.isRequired,
  isFetchingSponsor: PropTypes.bool.isRequired,
  // logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  fetchStatus: { sponsor: { isFetching: isFetchingSponsor } },
}) => ({
  isFetchingSponsor,
});

export default connect(mapStateToProps, { callApi })(Component);
