export default {
  en: {
    pageVisits: 'Page visits',
    attendeeDetailsObtained: 'Attendee details obtained',
    conversionRate: 'Conversion rate',
    meetingsWithAttendees: 'Meetings with attendees',
    attendeesInSponsorActivities: 'Attendees in sponsor activities',
    filesDownloaded: 'Files downloaded',
    activityCount: 'Clicked on join activity',
    attendeeDetails: 'Leads obtained',
    attendeeDetailsLabel: 'View all of the attendees who have given you permission to contact them.',
    download: 'Download',
    filter: 'Filter',
    name: 'Attendee name',
    jobTitle: 'Position',
    company: 'Company',
    telephone: 'Phone',
    email: 'Email',
    message: 'Message',
    leadDetails: 'Lead Details',
    seeDetails: 'See lead details',
    downloadSuccess: {
      title: 'Lead details requested!',
      message: 'Details will be sent to your associated email. Please check in a moment.',
      description: 'Attendees will be sent to your associated email. Please check in a moment.',
    },
    downloadError: {
      title: 'Download error!',
      message: 'There was an issue while requesting lead details. Please try again in a moment.',
      description: 'There was an issue while requesting attendee details. Please try again in a moment.',
    },
  },
  es: {
    pageVisits: 'Visitas a la página',
    attendeeDetailsObtained: 'Datos de participantes obtenidos',
    conversionRate: 'Tasa de conversión',
    meetingsWithAttendees: 'Reuniones con participantes',
    attendeesInSponsorActivities: 'Participantes en actividades de patrocinador',
    filesDownloaded: 'Archivos descargados',
    activityCount: 'Clicks en unirse a actividad',
    attendeeDetails: 'Leads obtenidos',
    attendeeDetailsLabel: 'Observa los datos de todos los participantes que te han dado permiso para ser contactados.',
    download: 'Descargar',
    filter: 'Filtrar',
    name: 'Nombre del participante',
    jobTitle: 'Posición',
    company: 'Compañía',
    telephone: 'Teléfono',
    email: 'Correo',
    message: 'Mensaje',
    leadDetails: 'Detalles del lead',
    seeDetails: 'Ver detalles del lead',
    downloadSuccess: {
      title: 'Detalles de Leads solicitados!',
      message: 'Los detalles serán enviados a tu correo. Por favor revísalo en un momento.',
      description: 'La lista de participantes será enviada a tu correo asociado. Por favor, revisa en un momento.',
    },
    downloadError: {
      title: 'Error en la descarga!',
      message: 'Hubo un error solicitando los detalles de tus leads. Por favor vuelve a intentarlo en un momento.',
      description: 'Hubo un error solicitando los detalles de tus asistentes. Por favor vuelve a intentarlo en un momento.',
    },
  },
  fr: {
    pageVisits: 'Visites de la page ',
    attendeeDetailsObtained: 'Coordonnées obtenus sur le participant ',
    conversionRate: 'Taux de conversion ',
    meetingsWithAttendees: 'Rendez-vous avec les participants',
    attendeesInSponsorActivities: 'Participants aux activités des sponsors',
    filesDownloaded: 'Fichiers téléchargés',
    activityCount: 'Clics pour rejoindre l\'activité',
    attendeeDetails: 'Prospects obtenus',
    attendeeDetailsLabel: 'Affichez tous les participants qui vous ont donné l\'autorisation de les contacter.',
    download: 'Télécharger',
    filter: 'Filtrer',
    name: 'Nom du participant',
    jobTitle: 'Fonction',
    company: 'Entreprise',
    telephone: 'Téléphone',
    email: 'E-mail ',
    message: 'Message',
    leadDetails: 'Coordonnées du prospect',
    seeDetails: 'Voir les détails du prospect',
    downloadSuccess: {
      title: 'Coordonnées du prospect demandées !',
      message: 'Les coordonnées seront envoyées à l\'adresse électronique associée. Veuillez vérifier dans un moment.',
      description: 'Les participants seront envoyés à votre adresse e-mail associée. Veuillez vérifier dans un instant',
    },
    downloadError: {
      title: 'Erreur de téléchargement !',
      message: 'Il y a eu un problème lors de la demande des coordonnées du prospect. Veuillez réessayer dans un moment.',
      description: 'Un problème est survenu lors de la demande des détails des participants. Veuillez réessayer dans un instant.',
    },
  },
  de: {
    pageVisits: 'Seitenbesuche',
    attendeeDetailsObtained: 'Angaben zum Teilnehmer eingeholt',
    conversionRate: 'Konversionsrate',
    meetingsWithAttendees: 'Treffen mit Teilnehmern',
    attendeesInSponsorActivities: 'Teilnehmer an Sponsorenaktivitäten',
    filesDownloaded: 'Heruntergeladene Dateien',
    activityCount: 'Auf Aktivität beitreten geklickt',
    attendeeDetails: 'Erhaltene Leads',
    attendeeDetailsLabel: 'Sehen Sie sich alle Teilnehmer an, die Ihnen die Erlaubnis gegeben haben, sie zu kontaktieren.',
    download: 'Herunterladen',
    filter: 'Filter',
    name: 'Name des Teilnehmers',
    jobTitle: 'Position',
    company: 'Firma',
    telephone: 'Telefon',
    email: 'E-Mail',
    message: 'Nachricht',
    leadDetails: 'Angaben zum Hauptdarsteller',
    seeDetails: 'Details zum Lead anzeigen',
    downloadSuccess: {
      title: 'Angaben zum Lead angefordert!',
      message: 'Einzelheiten werden an Ihre zugehörige E-Mail-Adresse gesendet. Bitte schauen Sie einen Moment vorbei.',
      description: 'Die Teilnehmer werden an Ihre zugehörige E-Mail-Adresse gesendet. Bitte checken Sie einen Moment ein.',
    },
    downloadError: {
      title: 'Fehler beim Herunterladen!',
      message: 'Beim Anfordern der Lead-Details ist ein Problem aufgetreten. Bitte versuchen Sie es gleich noch einmal.',
      description: 'Beim Anfordern der Teilnehmerinformationen ist ein Problem aufgetreten. Bitte versuchen Sie es gleich noch einmal.',
    },
  },
};
