import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from 'eventtia-ui-components/lib/Loader';
import callApi from '../../actions/callApi';

const withCurrentEvent = () => (WrappedComponent) => {
  const ComponentWithCurrentEvent = ({
    callApi: dispatchCallApi,
    events,
    isFetchingEvents,
    isFetchingAttendeeType,
    ...props
  }) => {
    const { eventUri } = useParams();
    const currentEvent = Object.values(events).find(({ eventUri: uri }) => uri === eventUri);
    useEffect(() => {
      if (eventUri && !currentEvent) dispatchCallApi('currentEvent', { eventUri });
    }, [dispatchCallApi, eventUri, currentEvent]);

    useEffect(() => {
      if (eventUri) dispatchCallApi('attendeeTypes', { eventUri });
    }, [dispatchCallApi, eventUri]);

    return (
      <>
        <Loader loading={isFetchingEvents && isFetchingAttendeeType} variant="absolute" />
        {!isFetchingEvents && !isFetchingAttendeeType && !!currentEvent && (
          <WrappedComponent
            {...props}
            currentEvent={currentEvent}
          />
        )}
      </>
    );
  };

  ComponentWithCurrentEvent.propTypes = {
    events: PropTypes.objectOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    isFetchingAttendeeType: PropTypes.bool.isRequired,
    isFetchingEvents: PropTypes.bool.isRequired,
    callApi: PropTypes.func.isRequired,
  };

  ComponentWithCurrentEvent.defaultProps = {
    events: {},
  };

  const mapStateToProps = ({
    entities: { events },
    fetchStatus: {
      currentEvent: { isFetching: isFetchingEvents },
      attendeeTypes: { isFetching: isFetchingAttendeeType },
    },
  }) => ({
    events,
    isFetchingEvents,
    isFetchingAttendeeType,
  });

  return connect(mapStateToProps, { callApi })(ComponentWithCurrentEvent);
};

export default withCurrentEvent;
