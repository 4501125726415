import { LOGOUT } from '../../actions/app';
import { fetchConfig } from '../../actions/callApi';
import { setToken, removeToken } from '../../helpers/auth';
import { getEntity } from '../../helpers/getters';

const [, loginSuccess] = fetchConfig.login.types;

export default (store) => (next) => (action) => {
  switch (action.type) {
    case loginSuccess: {
      if (!action.response) break;

      const { eventUri } = getEntity(store.getState().entities, 'events');

      const { auth_token: authToken } = action.response;

      setToken(eventUri, authToken);

      break;
    }
    case LOGOUT: {
      const { eventUri } = getEntity(store.getState().entities, 'events');

      removeToken(eventUri);

      break;
    }
    default:
      break;
  }

  return next(action);
};
