export default {
  en: {
    BasicInformationUpdated: 'Information updated!',
    BasicInformationUpdatedDescription: 'Sponsor information was successfully updated',
    BasicInformationUpdateError: 'Information update error!',
    BasicInformationUpdateErrorDescription: 'There was an issue updating the sponsor information',
    description: 'Add and edit your company’s logo, banner image, social media and basic information. You can do this before and during the event and changes will go live instantly.',
    aboutUs: 'About us',
    aboutUsPlaceholder: 'As a sponsor you have 800 characters available in the about us section to describe your company to attendees.',
    websiteExample: 'https://www.example.com/',
    sponsorLogo: 'Sponsor logo',
    sponsorSuggested: 'The suggested image size is 500 x 500px',
    bannerImage: 'Banner image',
    bannerSuggested: 'The suggested image size is 889 x 194px',
    social: {
      title: 'Social media links',
      placeHolderFacebook: 'Link to Facebook',
      placeHolderInstagram: 'Instagram username',
      placeHolderLinkedIn: 'Link to LinkedIn profile',
      placeHolderTwitter: 'Twitter username',
    },
    errors: {
      link: 'Please check the URL you’ve entered',
      username: 'Please check the username you’ve entered',
      limit: 'The limit must be greater than zero',
      nan: 'The limit must be positive number',
    },
    tagAndCategories: {
      title: 'Tags and categories',
      description: 'Make sure attendees find you by selecting the categories that align with your company.',
      categories: 'Categories',
      selectCategories: 'Select categories',
    },
  },
  es: {
    BasicInformationUpdated: 'Informacion actualizada!',
    BasicInformationUpdatedDescription: 'La informacion del patrocinador ha sido actualizada correctamente.',
    BasicInformationUpdateError: 'Error al actualizar informacion!',
    BasicInformationUpdateErrorDescription: 'Ocurrió un error actualizando la informacion del patrocinador',
    description: 'Añade y edita el logo de tu compañía, la imagen del banner, las redes sociales y la información básica. Puedes hacerlo antes y durante el evento y los cambios se harán efectivos al instante.',
    aboutUs: 'Acerca de nosotros',
    aboutUsPlaceholder: 'Como patrocinador tienes 500 caracteres disponibles en la sección acerca de nosotros para describir tu compañía a los asistentes',
    websiteExample: 'https://www.ejemplo.com/',
    sponsorLogo: 'Logo del patrocinador',
    sponsorSuggested: 'El tamaño de imagen sugerido es de 500 x 500px',
    bannerImage: 'Imagen del banner',
    bannerSuggested: 'El tamaño de imagen sugerido es de 889 x 194px',
    social: {
      title: 'Enlaces a las redes sociales',
      placeHolderFacebook: 'Enlace a Facebook',
      placeHolderInstagram: 'Nombre de usario de Instagram',
      placeHolderLinkedIn: 'Enlace al perfil de LinkedIn',
      placeHolderTwitter: 'Nombre de usario de Twitter',
    },
    errors: {
      link: 'Por favor comprueba la URL que has introducido',
      username: 'Por favor comprueba el nombre de usuario que has introducido',
      limit: 'El límite debe ser mayor que cero',
      nan: 'El límite debe ser un número',
    },
    tagAndCategories: {
      title: 'Etiquetas y categorías',
      description: 'Asegúrese de que los asistentes le encuentren seleccionando las categorías que se ajustan a su compañía',
      categories: 'Categorías',
      selectCategories: 'Selecciona las categorías',
    },
  },
  fr: {
    BasicInformationUpdated: 'Informations actualisées !',
    BasicInformationUpdatedDescription: 'Les informations sur le sponsor ont été correctement mises à jour.',
    BasicInformationUpdateError: 'Erreur de mise à jour des informations !',
    BasicInformationUpdateErrorDescription: 'Une erreur s\'est produite lors de la mise à jour des informations sur le sponsor.',
    description: 'Ajoutez et modifiez le logo de votre entreprise, l\'image de la bannière, les réseaux sociaux et les informations de base. Vous pouvez le faire avant et pendant l\'événement et les changements prendront effet instantanément.',
    aboutUs: 'A propos de nous',
    aboutUsPlaceholder: 'En tant que sponsor, vous disposez de 500 caractères dans la section \'Qui sommes-nous\' pour décrire votre entreprise aux participants.',
    websiteExample: 'https://www.exemple.com/',
    sponsorLogo: 'Logo du sponsor',
    sponsorSuggested: 'La taille d\'image suggérée est de 500 x 500px',
    bannerImage: 'Image de la bannière ',
    bannerSuggested: 'La taille suggérée de l\'image est de 889 x 194px.',
    social: {
      title: 'Liens vers les réseaux sociaux',
      placeHolderFacebook: 'Enlace a Facebook',
      placeHolderInstagram: 'Nom d\'utilisateur Instagram',
      placeHolderLinkedIn: 'Lien vers le profil LinkedIn',
      placeHolderTwitter: 'Nom d\'utilisateur Twitter',
    },
    errors: {
      link: 'Veuillez vérifier l\'URL que vous avez saisie',
      username: 'Veuillez vérifier le nom d\'utilisateur que vous avez saisi',
      limit: 'Le nombre doit être supérieur à zéro',
      nan: 'La limite doit être un nombre',
    },
    tagAndCategories: {
      title: 'Balises et catégories',
      description: 'Assurez-vous que les participants vous trouvent en sélectionnant les catégories qui correspondent à votre entreprise.',
      categories: 'Catégories',
      selectCategories: 'Sélectionnez les catégories',
    },
  },
  de: {
    BasicInformationUpdated: 'Die Informationen wurden aktualisiert!',
    BasicInformationUpdatedDescription: 'Die Sponsorinformationen wurden erfolgreich aktualisiert',
    BasicInformationUpdateError: 'Fehler beim Aktualisieren der Informationen!',
    BasicInformationUpdateErrorDescription: 'Es gab ein Problem beim Aktualisieren der Sponsorinformationen',
    description: 'Fügen Sie Ihr Firmenlogo, das Bannerbild, soziale Medien und grundlegende Informationen hinzu und bearbeiten Sie sie. Sie können das vor und während der Veranstaltung tun und die Änderungen werden sofort veröffentlicht.',
    aboutUs: 'Über uns',
    aboutUsPlaceholder: 'Als Sponsor stehen Ihnen im Abschnitt „Über uns“ 800 Zeichen zur Verfügung, um den Teilnehmern Ihr Unternehmen zu beschreiben.',
    websiteExample: 'https://www.example.com/',
    sponsorLogo: 'Logo des Sponsors',
    sponsorSuggested: 'Die empfohlene Bildgröße ist 500 x 500 Pixel',
    bannerImage: 'Bannerbild',
    bannerSuggested: 'Die empfohlene Bildgröße ist 889 x 194 Pixel',
    social: {
      title: 'Links zu sozialen Netzwerken',
      placeHolderFacebook: 'Link zu Facebook',
      placeHolderInstagram: 'Instagram-Nutzername',
      placeHolderLinkedIn: 'Link zum LinkedIn-Profil',
      placeHolderTwitter: 'Twitter-Nutzername',
    },
    errors: {
      link: 'Bitte überprüfen Sie die URL, die Sie eingegeben haben',
      username: 'Bitte überprüfen Sie den Benutzernamen, den Sie eingegeben haben',
      limit: 'Das Limit muss größer als Null sein',
      nan: 'Das Limit muss eine positive Zahl sein',
    },
    tagAndCategories: {
      title: 'Schlagworte und Kategorien',
      description: 'Stellen Sie sicher, dass die Teilnehmer Sie finden, indem Sie die Kategorien auswählen, die zu Ihrem Unternehmen passen.',
      categories: 'Kategorien',
      selectCategories: 'Wählen Sie Kategorien aus',
    },
  },
};
