export default {
  en: {
    dashboard: 'Dashboard',
    meetings: 'Meetings',
    basicInformation: 'Basic information',
    videos: 'Videos',
    sponsorActivities: 'Activities',
    files: 'Files',
    representatives: 'Representatives',
    stand: 'Stand',
    products: 'Products',
  },
  es: {
    dashboard: 'Dashboard',
    meetings: 'Reuniones',
    basicInformation: 'Información básica',
    videos: 'Videos',
    sponsorActivities: 'Actividades',
    files: 'Archivos',
    representatives: 'Representantes',
    stand: 'Stand',
    products: 'Productos',
  },
  fr: {
    dashboard: 'Tableau de bord',
    meetings: 'Rendez-vous',
    basicInformation: 'Informations générales',
    videos: 'Vidéos ',
    sponsorActivities: 'Activités',
    files: 'Fichiers',
    representatives: 'Représentants',
    stand: 'Stand',
    products: 'Produits',
  },
  de: {
    dashboard: 'Armaturenbrett',
    meetings: 'Treffen',
    basicInformation: 'Grundlegende Informationen',
    videos: 'Videos',
    sponsorActivities: 'Aktivitäten',
    files: 'Dateien',
    representatives: 'Vertreter',
    stand: 'Stehen',
    products: 'Produkte',
  },
};
