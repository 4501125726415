import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useTimer from '../../hooks/useTimer/index';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.darkGrey.light,
    opacity: 0.6,
  },
  darkThemeRoot: {
    color: theme.palette.common.white,
    opacity: 1,
  },
  currentTime: {
    marginLeft: theme.spacing(1),
  },
}));

const Clock = ({ darkTheme }) => {
  const { t } = useTranslation('global');
  const classes = useStyles();
  const [timestamp, setTimestamp] = useState('');

  const onTick = useCallback((now) => {
    setTimestamp(now.format('h:mm a [GMT] Z'));
  }, [setTimestamp]);
  useTimer({ onTick });

  return (
    <div className={clsx(classes.root, darkTheme && classes.darkThemeRoot)}>
      <AccessTimeIcon />
      <Typography className={classes.currentTime}>{`${t('dates.localTimeIs')} ${timestamp}`}</Typography>
    </div>
  );
};

Clock.propTypes = {
  darkTheme: PropTypes.bool,
};

Clock.defaultProps = {
  darkTheme: false,
};

export default Clock;
