import { combineReducers } from 'redux';
import entities from './entities';
import meta from './meta';
import fetchStatus from './fetchStatus';
import filters from './filters';
import { LOGOUT } from '../actions/app';

const appReducer = combineReducers({
  entities,
  meta,
  fetchStatus,
  filters,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) return appReducer({
    entities: {
      attendeeTypes: state.entities.attendeeTypes,
      attendeeTypeCustomFields: state.entities.attendeeTypeCustomFields,
    },
  }, action);

  return appReducer(state, action);
};

export default rootReducer;
