export default {
  en: {
    actions: {
      addTag: 'Add tag',
      createTag: 'Create tag',
      saveTag: 'Save tag',
      manageTags: 'Manage tags',
    },
    labels: {
      chooseTagName: 'Choose the name of the label you want to create',
      tagName: 'Tag name',
    },
  },
  es: {
    actions: {
      addTag: 'Agregar etiqueta',
      createTag: 'Crear etiqueta',
      saveTag: 'Guardar etiqueta',
      manageTags: 'Administrar etiquetas',
    },
    labels: {
      chooseTagName: 'Elige el nombre de la etiqueta que quieres crear',
      tagName: 'Nombre de la etiqueta',
    },
  },
  fr: {
    actions: {
      addTag: 'Ajouter une balise',
      createTag: 'Créer une balise',
      saveTag: 'Sauvegarder la balise',
      manageTags: 'Gérer les balise',
    },
    labels: {
      chooseTagName: 'Choisissez le nom de la balise que vous voulez créer.',
      tagName: 'Nom de la balise',
    },
  },
  de: {
    actions: {
      addTag: 'Tag hinzufügen',
      createTag: 'Tag erstellen',
      saveTag: 'Tag speichern',
      manageTags: 'Schlagworte verwalten',
    },
    labels: {
      chooseTagName: 'Wählen Sie den Namen des Labels, das Sie erstellen möchten',
      tagName: 'Tag-Name',
    },
  },
};
