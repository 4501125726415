import { mergeLocales } from 'eventtia-ui-components/lib/locales';
import buttons from 'eventtia-ui-components/lib/locales/buttons';
import currency from 'eventtia-ui-components/lib/locales/currency';
import attendees from './attendees';
import activities from './activities';
import events from './events';
import global from './global';
import tags from './tags';
import meetings from './meetings';
import sidebar from './sidebar';
import dashboard from './dashboard';
import videos from './videos';
import standManager from './standManager';
import basicInformation from './basicInformation';
import files from './files';
import representatives from './representatives';
import products from './products';

export default mergeLocales({
  attendees,
  activities,
  buttons,
  meetings,
  currency,
  events,
  global,
  tags,
  sidebar,
  dashboard,
  videos,
  standManager,
  basicInformation,
  files,
  representatives,
  products,
}, ['en', 'es', 'fr', 'de']);
