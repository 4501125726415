export default {
  en: {
    info: {
      moduleDescription: 'Your representatives act as the main point of contact for attendees looking to get more information or to schedule a meeting. If a representative is unavailable you can switch the button off and they will become hidden from the sponsor page.',
      ticketsAssigned: 'Tickets assigned',
      ticketsAvailable: 'Tickets available',
    },
    message: {
      representativeTitle: 'Representative',
      deleteRepresentativeDescription: 'Are you sure you want to delete this representative?',
      alreadyRegistered: 'This email {{typedEmail}} has already been registered as an attendee by organizers, you can associate this attendee as a representative <3>here</3>.',
      errorCreatingInfo: 'There was an issue creating the representative.',
      errorCreating: 'Error creating representative',
      errorUpdatingInfo: 'There was an issue updating the representative.',
      errorUpdating: 'Error updating representative',
      representativeDeleted: 'Representative deleted',
      deletedRepresentativeDescription: 'The representative has been succesfully deleted.',
      representativeCreated: 'Representative created',
      representativeCreatedInfo: 'The representative has been succesfully created.',
      representativeUpdated: 'Representative updated',
      representativeUpdatedInfo: 'The representative has been succesfully updated.',
      missinFields: 'Missing fields',
      missingFieldsInfo: 'There are missing fields that are required.',
    },
    actions: {
      editRepresentative: 'Edit representative',
      addRepresentative: 'Add representative',
    },
    sponsorCategories: {
      goldSponsor: 'Gold',
      silverSponsor: 'Silver',
      bronzeSponsor: 'Bronze',
    },
    availableTicketsDescription: 'As a {{category}} sponsor you have the following tickets available:',
    saveChanges: 'Save changes',
  },
  es: {
    info: {
      moduleDescription: 'Tus representantes actúan como el principal punto de contacto para los participantes que buscan obtener mas información o agendar una reunión. Si un representante no está disponible, puedes apagar el botón y ellos se ocultarán de la pagina de patrocinadores.',
      ticketsAssigned: 'Tiquetes asignados',
      ticketsAvailable: 'Tiquetes disponibles',
    },
    message: {
      representativeTitle: 'Representante',
      deleteRepresentativeDescription: '¿Estás seguro de eliminar este representante?',
      alreadyRegistered: 'El correo {{typedEmail}} ya ha sido registrado como participante por los organizadores, puedes asociar este participante como representante <3>aquí</3>.',
      errorCreatingInfo: 'Hubo un problema creando el representante.',
      errorCreating: 'Error creando el representante',
      errorUpdatingInfo: 'Hubo un problema actualizando el representante.',
      errorUpdating: 'Error actualizando el representante',
      representativeDeleted: 'Representante eliminado',
      deletedRepresentativeDescription: 'Representante eliminado exitosamente.',
      representativeCreated: 'Representante creado',
      representativeCreatedInfo: 'El representante ha sido creado exitosamente.',
      representativeUpdated: 'Representante actualizado',
      representativeUpdatedInfo: 'El representante ha sido actualizado exitosamente.',
      missinFields: 'Campos faltantes',
      missingFieldsInfo: 'Faltan campos obligatorios.',
    },
    actions: {
      editRepresentative: 'Editar representante',
      addRepresentative: 'Añadir representante',
    },
    sponsorCategories: {
      goldSponsor: 'Oro',
      silverSponsor: 'Plata',
      bronzeSponsor: 'Bronce',
    },
    availableTicketsDescription: 'Como un patrocinador {{category}} tienes los siguientes tiquetes disponibles:',
    saveChanges: 'Guardar cambios',
  },
  fr: {
    info: {
      moduleDescription: 'Vos représentants sont les principaux points de contact pour les participants souhaitant obtenir plus d\'informations, ou programmer un rendez-vous. Si un représentant est indisponible, vous pouvez désactiver le bouton pour le cacher de la page sponsor. ',
      ticketsAssigned: 'Tickets assignés',
      ticketsAvailable: 'Tickets disponibles',
    },
    message: {
      representativeTitle: 'Représentant',
      deleteRepresentativeDescription: 'Voulez-vous vraiment supprimer cette représentant?',
      alreadyRegistered: 'Cet e-mail {{typedEmail}} a déjà été enregistré comme participant par les organisateurs. Vous pouvez associer ce participant comme représentant <3>ici</3>.',
      errorCreatingInfo: 'Un problème est survenu lors de la création du représentant.',
      errorCreating: ' Erreur lors de la création du représentant',
      errorUpdatingInfo: 'Une erreur est survenue dans la mise à jour du représentant.',
      errorUpdating: 'Erreur lors de la mise à jour du représentant',
      representativeDeleted: 'Représentant supprimé',
      deletedRepresentativeDescription: 'Le représentant a été supprimé avec succès.',
      representativeCreated: 'Représentant créé',
      representativeCreatedInfo: 'Le représentant a été créé avec succès.',
      representativeUpdated: 'Le représentant a été mis à jour',
      representativeUpdatedInfo: 'Le représentant a été mis à jour avec succès.',
      missinFields: 'Champs manquants',
      missingFieldsInfo: 'Il manque des champs obligatoires.',
    },
    actions: {
      editRepresentative: 'Edit representative',
      addRepresentative: 'Ajouter représentant',
    },
    sponsorCategories: {
      goldSponsor: 'Or',
      silverSponsor: 'Argent',
      bronzeSponsor: 'Bronze',
    },
    availableTicketsDescription: 'En tant que {{category}} sponsor voici vos tickets disponibles:',
    saveChanges: 'Sauvegarder les modifications',
  },
  de: {
    info: {
      moduleDescription: 'Ihre Vertreter sind die Hauptansprechpartner für Teilnehmer, die weitere Informationen erhalten oder einen Termin vereinbaren möchten. Wenn ein Vertreter nicht verfügbar ist, können Sie die Schaltfläche ausschalten und er wird auf der Sponsorenseite ausgeblendet.',
      ticketsAssigned: 'Tickets zugewiesen',
      ticketsAvailable: 'Tickets verfügbar',
    },
    message: {
      representativeTitle: 'Repräsentant',
      deleteRepresentativeDescription: 'Sind Sie sicher, dass Sie diesen Vertreter löschen möchten?',
      alreadyRegistered: '<3>Diese E-Mail {{typedEmail}} wurde bereits von den Organisatoren als Teilnehmer registriert. Sie können diesen Teilnehmer hier als Vertreter zuordnen.</3>',
      errorCreatingInfo: 'Es gab ein Problem bei der Erstellung des Repräsentanten.',
      errorCreating: 'Fehler beim Erstellen eines Vertreters',
      errorUpdatingInfo: 'Es gab ein Problem bei der Aktualisierung des Mitarbeiters.',
      errorUpdating: 'Fehler beim Aktualisieren des Repräsentanten',
      representativeDeleted: 'Vertreter gelöscht',
      deletedRepresentativeDescription: 'Der Vertreter wurde erfolgreich gelöscht.',
      representativeCreated: 'Vertreter wurde gegründet',
      representativeCreatedInfo: 'Der Vertreter wurde erfolgreich erstellt.',
      representativeUpdated: 'Vertreter aktualisiert',
      representativeUpdatedInfo: 'Der Vertreter wurde erfolgreich aktualisiert.',
      missinFields: 'Fehlende Felder',
      missingFieldsInfo: 'Es fehlen Felder, die erforderlich sind.',
    },
    actions: {
      editRepresentative: 'Vertreter bearbeiten',
      addRepresentative: 'Vertreter hinzufügen',
    },
    sponsorCategories: {
      goldSponsor: 'Gold',
      silverSponsor: 'Silber',
      bronzeSponsor: 'Bronze',
    },
    availableTicketsDescription: 'Als {{category}} Sponsor stehen Ihnen die folgenden Tickets zur Verfügung:',
    saveChanges: 'Änderungen speichern',
  },
};
